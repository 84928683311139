import { RebillyApiWrapper } from '@xylabs/sdk-coin-js'
import { ApiStage } from '@xyo-network/sdk-xyo-js'
import React from 'react'

interface ApiContextProps {
  apiDomain?: string
  apiStage?: ApiStage
  error?: Error
  rebillyApi?: RebillyApiWrapper
  refreshAll?: () => void
}

const ApiContext = React.createContext<ApiContextProps>({})
export default ApiContext

import { Typography, useTheme } from '@material-ui/core'
import { FlexPaper } from '@xyo-network/sdk-xyo-react-js'
import React from 'react'

interface Props {
  bgcolor?: string
  squareCorners?: boolean
  tag: string
}

const Tag: React.FC<Props> = (props) => {
  const theme = useTheme()
  const { tag, bgcolor = theme.palette.secondary.main, squareCorners = false } = props

  const optionsParams = squareCorners ? { borderRadius: 0 } : {}

  return (
    <FlexPaper
      {...optionsParams}
      variant="outlined"
      paddingX={0.5}
      bgcolor={bgcolor}
      color={theme.palette.getContrastText(bgcolor)}
    >
      <Typography variant="caption">{tag}</Typography>
    </FlexPaper>
  )
}

export default Tag

import { CoinRedeemSku } from '@xylabs/sdk-coin-js'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import FireCubePage from './FireCubePage'
import FryerPage from './FryerPage'
import IPadPage from './IPadPage'
import Playstation5Page from './Playstation5Page'
import SamsungPage from './SamsungPage'
import SwitchPage from './SwitchPage'
import XboxPage from './XboxPage'
import XyoPage from './XyoPage'

const Pages: React.FC = () => {
  return (
    <Switch>
      <Route component={Playstation5Page} exact path={'/'} />
      <Route component={Playstation5Page} exact path={'/start/ps5'} />
      <Route component={Playstation5Page} exact path={`/start/${CoinRedeemSku.PlayStation5}`} />
      <Route component={XboxPage} exact path={`/start/${CoinRedeemSku.XboxSeriesX}`} />
      <Route component={XyoPage} exact path={`/start/${CoinRedeemSku.OneMillionXyoTokens}`} />
      <Route component={SwitchPage} exact path={`/start/${CoinRedeemSku.NintendoSwitch}`} />
      <Route component={FireCubePage} exact path={`/start/${CoinRedeemSku.AmazonFireCube}`} />
      <Route component={IPadPage} exact path={`/start/${CoinRedeemSku.IPad}`} />
      <Route component={FryerPage} exact path={`/start/${CoinRedeemSku.CosoriSmartAirFryer}`} />
      <Route component={SamsungPage} exact path={`/start/${CoinRedeemSku.Samsung65Inch}`} />
      <Route>
        <Redirect to="/start/ps5" />
      </Route>
    </Switch>
  )
}

export default Pages

import { Typography } from '@material-ui/core'
import { CoinFeedbackSubmissionJson, CoinFeedbackSubmissionStatus } from '@xylabs/sdk-coin-js'
import { FlexGrowCol, FlexPaper, useAsyncEffect } from '@xyo-network/sdk-xyo-react-js'
import { WithId } from 'mongodb'
import { useState } from 'react'
import { match } from 'react-router-dom'

import { useApi } from '../../../Contexts'
import BasePage from '../../BasePage'
import FeedbackViewer from './FeedbackViewer'
import ReviewToolbar from './Toolbar'

interface Props {
  match: match<{ filter: CoinFeedbackSubmissionStatus }>
}

const Page: React.FC<Props> = (props) => {
  const { match } = props
  const filter = match?.params?.filter ?? CoinFeedbackSubmissionStatus.Submitted
  const api = useApi()
  const [allFeedback, setAllFeedback] = useState<WithId<CoinFeedbackSubmissionJson>[]>()

  useAsyncEffect(
    async (mounted) => {
      if (api?.authenticated) {
        setAllFeedback(undefined)
        const allFeedback = await api.fetchFeedback(filter)
        if (mounted()) {
          setAllFeedback(allFeedback)
        }
      }
    },
    [api, filter]
  )

  return (
    <BasePage toolbar={<ReviewToolbar filter={filter} />} container="sm" authOnly>
      <FlexGrowCol alignItems="stretch" marginY={2} marginX={1} justifyContent="flex-start" busy={!allFeedback}>
        {allFeedback?.map((feedback, index) => {
          return (
            <FlexPaper marginY={2} key={index}>
              <FeedbackViewer feedback={feedback} filter={filter} />
            </FlexPaper>
          )
        })}
        {allFeedback?.length === 0 ? <Typography align="center">No items found</Typography> : null}
      </FlexGrowCol>
    </BasePage>
  )
}

export default Page

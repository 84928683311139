import { Grid, useTheme } from '@material-ui/core'
import { CoinSweepstakeHistoryJson } from '@xylabs/sdk-coin-js'
import { useAsyncEffect } from '@xyo-network/sdk-xyo-react-js'
import { useState } from 'react'

import { useApi } from '../../../Contexts'
import { SweepstakeCard } from '.'

const Dashboard: React.FC = () => {
  const api = useApi(true)
  const theme = useTheme()

  const [sweepstakes, setSweepstakes] = useState<CoinSweepstakeHistoryJson[]>()

  useAsyncEffect(
    async (mounted) => {
      if (api?.authenticated) {
        const sweepstakes = await api.getUserSweepstakes()
        if (mounted()) {
          setSweepstakes(sweepstakes)
        }
      }
    },
    [api?.authenticated]
  )

  return (
    <Grid container>
      {sweepstakes?.map((sweepstake) => {
        return sweepstake ? (
          <Grid item key={sweepstake.optionsId.toString()}>
            <SweepstakeCard sweepstake={sweepstake} style={{ margin: theme.spacing(1) }} />
          </Grid>
        ) : null
      })}
    </Grid>
  )
}

export default Dashboard

import { CoinUserStat, UserStatsContext } from '@xyo-network/sdk-coin-react-js'
import { useAsyncEffect } from '@xyo-network/sdk-xyo-react-js'
import { DateTime } from 'luxon'
import React, { PropsWithChildren, useState } from 'react'

import { useApi } from './Api'

interface Props {
  end?: number
  sanitize?: boolean
  start?: number
}

const Provider: React.FC<PropsWithChildren<Props>> = (props) => {
  const {
    start = parseInt(DateTime.now().minus({ days: 8 }).toFormat('yyyyMMdd')),
    end = parseInt(DateTime.now().toFormat('yyyyMMdd')),
    sanitize = true,
  } = props
  const [stats, setStats] = useState<CoinUserStat[]>()
  const [error, setError] = useState<Error>()
  const [refreshCount, setRefreshCount] = useState(0)
  const api = useApi()
  useAsyncEffect(
    async (mounted) => {
      if (api && api.authenticated) {
        try {
          if (mounted()) {
            setError(undefined)
            setStats(undefined)
          }
          const result = await (refreshCount === 0
            ? api.getUserStatsXyo(start, end, sanitize)
            : api.fetchUserStatsXyo(start, end, sanitize))
          if (mounted()) {
            setStats(result.stats)
          }
        } catch (ex) {
          if (mounted()) {
            setError(ex)
          }
        }
      }
    },
    [api, sanitize, refreshCount]
  )

  const refresh = () => {
    setRefreshCount(refreshCount + 1)
  }

  return <UserStatsContext.Provider value={{ error, refresh, stats }} {...props} />
}

export default Provider

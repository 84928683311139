import { RebillyApiWrapper } from '@xylabs/sdk-coin-js'
import { ApiStage } from '@xyo-network/sdk-xyo-js'
import { isLocalhost } from '@xyo-network/sdk-xyo-react-js'
import React, { PropsWithChildren, useEffect, useState } from 'react'

import RebillyApiContext from './Context'

interface Props {
  uri?: string
}

const organizationId = 'd3fbb7ce-c0fb-494b-b7f2-c8987d28f043'

const Provider: React.FC<PropsWithChildren<Props>> = (props) => {
  const apiStage = isLocalhost
    ? ApiStage.Local
    : document.location.hostname.startsWith('beta')
    ? ApiStage.Beta
    : ApiStage.Prod

  const apiDomain = isLocalhost ? 'https://api-sandbox.rebilly.com' : 'https://api.rebilly.com'
  const publishedKey = isLocalhost
    ? 'pk_sandbox_iLHw84erBne6XZ2ryqUPb1xyCS1vpMc6BgoVYk3'
    : 'pk_live_1pUi_HArNXWDP5tNgqTDoqqwOv68BeFqzyjd9uX'

  const [rebillyApi, setRebillyApi] = useState<RebillyApiWrapper>()
  const [refreshCount, setRefreshCount] = useState(0)
  const [error, setError] = useState<Error>()

  const refreshAll = () => {
    setError(undefined)
    setRebillyApi(undefined)
    setRefreshCount(refreshCount + 1)
  }

  useEffect(() => {
    if (apiDomain) {
      const api = new RebillyApiWrapper(organizationId, publishedKey, undefined, isLocalhost)
      setRebillyApi(api)
    }
  }, [apiDomain, refreshCount, publishedKey])

  return (
    <RebillyApiContext.Provider
      value={{
        apiDomain,
        apiStage,
        error,
        rebillyApi,
        refreshAll,
      }}
      {...props}
    />
  )
}

export default Provider

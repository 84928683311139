import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import GraphJsonPage from './GraphJson'
import JsonPage from './Json'
import OffersPage from './Offers'

const Pages: React.FC = () => {
  return (
    <Switch>
      <Route component={OffersPage} exact path="/offers" />
      <Route component={JsonPage} exact path="/offers/json" />
      <Route component={GraphJsonPage} exact path="/offers/graph" />
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  )
}

export default Pages

import { BoxProps, Checkbox, FormControl, FormControlLabel, TextField, Typography } from '@material-ui/core'
import { useUserEvents } from '@xyo-network/sdk-coin-react-js'
import { useAuth } from '@xyo-network/sdk-coin-react-js/auth'
import { delay } from '@xyo-network/sdk-xyo-js'
import { ButtonEx, FlexCol, FlexGrowRow, FlexRow, MessageDialog, useMounted } from '@xyo-network/sdk-xyo-react-js'
import React, { ChangeEvent, useState } from 'react'

import { checkSingleEmail, DebounceCode } from '../lib'

interface Props extends BoxProps {
  hideSignin?: boolean
  onAccountCreated?: () => void
}

const CreateAccountBox: React.FC<Props> = (props) => {
  const { hideSignin, title = 'Sign Up', onAccountCreated, ...boxProps } = props
  const auth = useAuth()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState<string>()
  const [showPasswordChecked, setShowPasswordChecked] = useState(false)
  const [emailError, setEmailError] = useState<boolean>()
  const [busy, setBusy] = useState(false)
  const [showErrorDialog, setShowErrorDialog] = useState(false)
  const userEvents = useUserEvents()

  const isMounted = useMounted()

  const onCreate = async () => {
    if (password.length < 8 || emailError) {
      setShowErrorDialog(true)
    } else {
      if (auth) {
        try {
          setBusy(true)
          await Promise.all([auth.createUserWithEmailAndPassword(email, password), delay(500)])
          userEvents.accountCreated()
          onAccountCreated?.()
        } catch (ex) {
          setError(ex.message ?? ex.toString())
          if (isMounted()) {
            setBusy(false)
          }
        } finally {
          if (isMounted()) {
            setBusy(false)
          }
        }
      }
    }
  }

  const onEmailBlur = async () => {
    setBusy(true)
    const result = (await Promise.all([checkSingleEmail(email), delay(500)]))[0]
    setBusy(false)
    if (result.debounce?.error) {
      setError(result.debounce?.error)
      setEmailError(true)
    } else if (result.debounce?.send_transactional === '1') {
      userEvents.emailCollected({ email: email ?? '' })
      setEmailError(false)
      return
    } else {
      setEmailError(true)
      switch (result.debounce?.code) {
        case DebounceCode.Disposable:
          setError('Disposable emails are not allowed.')
          break
        case DebounceCode.Invalid:
          setError('We could not reach that email.  Please try a different email.')
          break
        case DebounceCode.Syntax:
          setError('That does not seem to be a valid email.')
          break
        default:
          setError(`Email Error [${result.debounce?.code}]`)
          break
      }
    }
  }

  const onEmailChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEmail(event.target.value)
    setEmailError(false)
    setError(undefined)
  }

  const onPasswordChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPassword(event.target.value)
  }

  const onShowPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowPasswordChecked(event.target.checked)
  }

  return (
    <FlexCol alignItems="stretch" busy={busy} {...boxProps}>
      <FlexRow margin={2} justifyContent="flex-start">
        <Typography variant="h3">{title}</Typography>
      </FlexRow>
      <FlexRow margin={2}>
        <TextField
          id="email"
          label="Email"
          onChange={onEmailChange}
          onBlur={onEmailBlur}
          error={emailError}
          type="email"
          value={email}
          variant="outlined"
          fullWidth
        />
      </FlexRow>
      <FlexCol margin={2} alignItems="flex-start">
        <TextField
          id="password"
          label="Password"
          onChange={onPasswordChange}
          type={showPasswordChecked ? 'text' : 'password'}
          value={password}
          variant="outlined"
          fullWidth
        />
        <FormControl>
          <FormControlLabel
            control={<Checkbox checked={showPasswordChecked} name="showPassword" onChange={onShowPasswordChange} />}
            label="Show Password"
          />
        </FormControl>
      </FlexCol>
      <FlexRow minHeight={20} margin={1}>
        <Typography color="error" variant="caption">
          {error}
        </Typography>
      </FlexRow>
      <FlexGrowRow margin={2} justifyContent="flex-start">
        <ButtonEx size="medium" paddingX={4} onClick={onCreate} variant="contained" color="secondary">
          Sign Up
        </ButtonEx>
        {hideSignin ? null : (
          <ButtonEx
            marginLeft={1}
            marginY={1}
            paddingX={4}
            size="medium"
            to="/account/signin"
            variant="text"
            color="inherit"
          >
            Sign In
          </ButtonEx>
        )}
      </FlexGrowRow>
      <MessageDialog onClose={() => setShowErrorDialog(false)} open={showErrorDialog} title={'Invalid Fields'}>
        <Typography variant="body1">Please correct all fields before continuing</Typography>
      </MessageDialog>
    </FlexCol>
  )
}

export default CreateAccountBox

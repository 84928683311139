import { BoxProps } from '@material-ui/core'
import { FlexCol, FlexGrowRow, FlexRow } from '@xyo-network/sdk-xyo-react-js'
import React from 'react'

import { CreateAccountBox } from '../../../../Components'
import logo from '../../img/logo-full.svg'
import BottomMessage from './BottomMessage'

const CreateAccount: React.FC<BoxProps> = (props) => {
  return (
    <FlexCol flexGrow={1} flexBasis={50} alignItems="stretch" padding={2} {...props}>
      <FlexRow justifyContent="flex-start" margin={1}>
        <img src={logo} title="COIN Logo" height={48} />
      </FlexRow>
      <CreateAccountBox title="Claim your Entries!" marginY={1} hideSignin />
      <FlexGrowRow />
      <BottomMessage />
    </FlexCol>
  )
}

export default CreateAccount

import React from 'react'
import { Redirect } from 'react-router-dom'

import BasePage from '../BasePage'

const DetailsPage: React.FC = () => {
  return (
    <BasePage container="xl" authOnly>
      <Redirect to="/u" />
    </BasePage>
  )
}

export default DetailsPage

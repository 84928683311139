import { BoxProps, Typography } from '@material-ui/core'
import { useUserEvents } from '@xyo-network/sdk-coin-react-js'
import { ButtonEx, FlexCol } from '@xyo-network/sdk-xyo-react-js'

const Upsell: React.FC<BoxProps> = (props) => {
  const userEvents = useUserEvents()
  const onClick = () => {
    userEvents.upsellAccepted()
    window.open('https://coinapp.co/free/sentinelx/nfc/?utm_source=appsite')
  }
  return (
    <FlexCol {...props}>
      <Typography variant="body1" align="center">
        Want to Supercharge your COIN App to get even more entries?
      </Typography>
      <ButtonEx margin={1} variant="contained" color="primary" onClick={onClick}>
        Get a Free all new SentinelX
      </ButtonEx>
    </FlexCol>
  )
}

export default Upsell

import 'firebase/auth'

import firebase from 'firebase/app'

//we do this check since when reloading during dev causes a double init
const isFirebaseAppInitialized = (name: string) => {
  for (const app of firebase.apps) {
    if (app.name === name) {
      return true
    }
  }
  return false
}

class Authentication {
  private _app: firebase.app.App
  private _onAuthStateChangedUnsubscribe?: firebase.Unsubscribe
  private _onAuthStateChanged?: (user: firebase.User | null) => void
  private _initialized = false

  private constructor(
    name: string,
    options: Record<string, unknown>,
    onAuthStateChanged?: (user: firebase.User | null) => void
  ) {
    this._onAuthStateChanged = onAuthStateChanged
    this._app = isFirebaseAppInitialized(name) ? firebase.app(name) : firebase.initializeApp(options, name)
  }

  private async init() {
    this._onAuthStateChangedUnsubscribe = this.auth.onAuthStateChanged((user: firebase.User | null) => {
      this._initialized = true
      this._onAuthStateChanged?.(user)
    })
    await this.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  }

  public close() {
    this._onAuthStateChangedUnsubscribe?.()
  }

  static async create(name: string, options: any, onAuthStateChanged?: (user: firebase.User | null) => void) {
    const auth = new Authentication(name, options, onAuthStateChanged)
    await auth.init()
    return auth
  }

  public get auth() {
    return this._app.auth()
  }

  public get initialized() {
    return this._initialized
  }

  public get user() {
    if (this._initialized) {
      return this.auth.currentUser
    } else {
      return undefined
    }
  }

  public get authenticated() {
    return this.user != null
  }

  public async sendPasswordResetEmail(email: string) {
    return await this.auth.sendPasswordResetEmail(email)
  }

  public async signUp(email: string, password: string, navigate?: (to: string) => void) {
    if (this.returnTo) {
      const to = this.returnTo
      navigate?.(to)
    }
    await this.auth.createUserWithEmailAndPassword(email, password)
    return this.user
  }

  public async signIn(email: string, password: string, navigate?: (to: string) => void) {
    await this.auth.signInWithEmailAndPassword(email, password)
    if (this.returnTo) {
      const to = this.returnTo
      navigate?.(to)
    }
    return this.user
  }

  public signOut() {
    return this.auth.signOut()
  }

  public returnTo?: string

  public async updatePassword(password: string) {
    try {
      const user = this.user
      if (user) {
        return await user.updatePassword(password)
      }
    } catch (err) {
      throw new Error(err)
    }

    return false
  }

  public async signInWithFacebook() {
    const provider = new firebase.auth.FacebookAuthProvider()
    return await this.auth.signInWithPopup(provider)
  }

  public async signInWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider()
    return await this.auth.signInWithPopup(provider)
  }
}

export default Authentication

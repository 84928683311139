import { DateTime } from 'luxon'
import React from 'react'

import Timer from './Timer'

interface Props {
  endTime?: DateTime
}

const EndTimer: React.FC<Props> = (props) => {
  const { endTime } = props

  let prefix = ''
  const endTimeMs = endTime?.toMillis()
  prefix = DateTime.fromMillis(endTimeMs ?? 0) > DateTime.now() ? 'Ends' : 'Ended'
  return <Timer prefix={prefix} time={endTimeMs} />
}

export default EndTimer

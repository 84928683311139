import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Step,
  StepContent,
  StepLabel,
  StepProps,
  TextField,
  Typography,
} from '@material-ui/core'
import { processPayment, ProcessPaymentParams } from '@xylabs/sdk-coin-js'
import { assertEx } from '@xyo-network/sdk-xyo-js'
import { FlexCol, FlexRow, isLocalhost } from '@xyo-network/sdk-xyo-react-js'
import React, { ChangeEvent, useState } from 'react'

import { CreditCard } from '../../../../Components'
import { useApi } from '../../../../Contexts'

interface Props extends StepProps {
  email?: string
  entryBoostAccepted?: boolean
  entrySelection?: number
  onContinue?: () => void
  optionsId: string
  stepName?: string
  subtitle?: string
}

const CustomStep: React.FC<Props> = (props) => {
  const { onContinue, optionsId, email, stepName = 'Buy More Tickets', subtitle = '', ...stepProps } = props
  const [validCreditCard, setValidCreditCard] = useState(false)
  const [entrySelection, setEntrySelection] = useState(100)
  const [creditcard, setCreditcard] = useState('')
  const [expiry, setExpiry] = useState('')
  const [cvv, setCvv] = useState('')
  const [first, setFirst] = useState('')
  const [last, setLast] = useState('')
  const [zip, setZip] = useState('')
  const [error, setError] = useState<string>()
  const [paymentBusy, setPaymentBusy] = useState(false)

  const api = useApi()

  const onLocalContinue = () => {
    onContinue?.()
  }

  const onRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEntrySelection(parseInt(event.target.value))
  }

  const onFirstChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFirst(event.target.value)
  }

  const onLastChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLast(event.target.value)
  }

  const onZipChange = (event: ChangeEvent<HTMLInputElement>) => {
    setZip(event.target.value)
  }

  const onCreditCardChange = (creditcard: string, expiry: string, cvc: string, valid: boolean) => {
    setValidCreditCard(valid)
    if (valid) {
      setCreditcard(creditcard)
      setExpiry(expiry)
      setCvv(cvc)
      setEntrySelection(2500)
    }
  }

  const onPurchase = async () => {
    if (!validCreditCard) {
      setError('Please enter valid creditcard information')
      return
    }
    if (first.length === 0) {
      setError('Please enter a first name')
      return
    }
    if (last.length === 0) {
      setError('Please enter a last name')
      return
    }
    if (zip.length === 0) {
      setError('Please enter a zip/postal code')
      return
    }
    setPaymentBusy(true)
    const address = {
      email,
      emails: [
        {
          label: 'main',
          primary: true,
          value: assertEx(email, 'Missing email'),
        },
      ],
      firstName: first,
      lastName: last,
      postalCode: zip,
    }
    try {
      const params: ProcessPaymentParams = {
        billing: address,
        cartInfo: {
          items: [
            {
              planId: '2500-SWEEPSTAKES-ENTRIES',
              price: 9.95,
              quantity: 1,
            },
          ],
          total: 9.95,
        },
        customer: {
          email,
        },
        paymentInfo: {
          creditcard,
          cvc: cvv,
          expiry,
        },
        sandbox: !!isLocalhost,
      }
      const result = await processPayment(params)
      if ((result.errors?.length ?? 0) > 0) {
        console.log(`ERROR: ${JSON.stringify(result)}`)
        setError(result.errors?.map((error) => error.message).join(','))
      } else {
        try {
          api?.paidEntry(
            assertEx(result.data?.checkout?.transaction?.id, 'Missing transactionid'),
            optionsId,
            2500,
            assertEx(email, 'Missing email'),
            'funnel-paid'
          )
        } catch (ex) {
          setError(ex)
        }
        onContinue?.()
      }
    } finally {
      setPaymentBusy(false)
    }
  }

  return (
    <Step {...stepProps}>
      <StepLabel>
        <Typography variant="h4">{stepName}</Typography>
      </StepLabel>
      <StepContent>
        <FlexCol alignItems="stretch" paddingY={1} busy={paymentBusy}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              <Typography variant="body1">{subtitle}</Typography>
            </FormLabel>
            <RadioGroup
              aria-label="entrySelection"
              name="entrySelection"
              value={entrySelection}
              onChange={onRadioChange}
            >
              <FormControlLabel value={100} control={<Radio />} label="100 Entries" />
              <FormControlLabel value={2500} control={<Radio />} label="2,500 Entries (25x more likely to win!)" />
            </RadioGroup>
          </FormControl>

          <FlexCol display={entrySelection === 2500 ? 'flex' : 'none'}>
            <FlexRow marginY={0.5}>
              <Typography variant="body2">
                Good choice! This option is only $9.95 for 2,000 paid entries + we will 5x the number of free entries as
                a bonus to give you a total of 2,500 entries!
              </Typography>
            </FlexRow>
            <FlexRow marginY={0.5}>
              <FlexRow marginRight={1} flexGrow={1}>
                <TextField
                  margin="dense"
                  variant="outlined"
                  label="First name"
                  type="text"
                  fullWidth
                  onChange={onFirstChange}
                  value={first ?? ''}
                  autoFocus
                  autoComplete="first"
                />
              </FlexRow>
              <FlexRow marginRight={1} flexGrow={1}>
                <TextField
                  margin="dense"
                  variant="outlined"
                  label="Last name"
                  type="text"
                  fullWidth
                  onChange={onLastChange}
                  value={last ?? ''}
                  autoComplete="last"
                />
              </FlexRow>
              <FlexRow flexGrow={1}>
                <TextField
                  margin="dense"
                  variant="outlined"
                  label="Zip code"
                  type="text"
                  fullWidth
                  onChange={onZipChange}
                  value={zip ?? ''}
                />
              </FlexRow>
            </FlexRow>
            <FlexRow marginY={0.5}>
              <CreditCard onChange={onCreditCardChange} hideError hideLogos />
            </FlexRow>
            <FlexRow style={{ opacity: error ? 1 : 0 }}>
              <Typography color="error" variant="body1" align="center" style={{ zoom: 0.7 }}>
                {error}
              </Typography>
            </FlexRow>
          </FlexCol>
          <FlexRow paddingBottom={1}>
            <Button fullWidth variant="outlined" onClick={onLocalContinue} color="primary">
              Continue with 100 Entries
            </Button>
          </FlexRow>
          <Button
            disabled={entrySelection !== 2500}
            fullWidth
            variant="contained"
            onClick={onPurchase}
            color="secondary"
          >
            Continue with 2,500 Entries
          </Button>
        </FlexCol>
      </StepContent>
    </Step>
  )
}

export default CustomStep

/* eslint-disable */
import React, { Component } from 'react'
import valid from 'card-validator'
import creditCardType from 'credit-card-type'
import styled, { withProps } from './styled-components'

import {
  formatCardNumber,
  formatExpiry,
  formatCvc,
  hasCardNumberReachedMaxLength,
  hasCVCReachedMaxLength,
  hasZipReachedMaxLength,
  isHighlighted,
} from './utils/formatter'
import images from './utils/images'
import isExpiryInvalid from './utils/is-expiry-invalid'
import isZipValid from './utils/is-zip-valid'

const global = window as any
global.arie = valid

const Container = withProps<any>()(styled.div)`
  display: inline-block;
  ${({ styled }) => ({ ...styled })};
`

const FieldWrapper = withProps<any>()(styled.div)`
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px;
  overflow: hidden;
  ${({ styled }) => ({ ...styled })};
`

const CardImage = withProps<any>()(styled.img)`
  height: 1em;
  ${({ styled }) => ({ ...styled })};
`

const InputWrapper = withProps<any>()(styled.label)`
  align-items: center;
  display: ${(props) => (props.isActive ? 'flex' : 'none')};
  margin-left: 0.5em;
  position: relative;
  transition: transform 0.5s;
  transform: translateX(${(props) => (props.translateX ? '4rem' : '0')});

  &::after {
    content: attr(data-max);
    visibility: hidden;
    height: 0;
  }

  & .credit-card-input {
    border: 0px;
    position: absolute;
    width: 100%;
    font-size: 1em;
    ${({ inputStyled }) => ({ ...inputStyled })};

    &:focus {
      outline: 0px;
    }
  }

  & .zip-input {
    display: ${(props) => (props.isZipActive ? 'flex' : 'none')};
  }
`

const DangerText = withProps<any>()(styled.p)`
  font-size: 0.8rem;
  margin: 5px 0 0 0;
  color: #ff3860;
  ${({ styled }) => ({ ...styled })};
`

const BACKSPACE_KEY_CODE = 8
const CARD_TYPES = {
  amex: 'AMERICAN_EXPRESS',
  dinersclub: 'DINERS_CLUB',
  discover: 'DISCOVER',
  elo: 'ELO',
  jcb: 'JCB',
  maestro: 'MAESTRO',
  mastercard: 'MASTERCARD',
  unionpay: 'UNIONPAY',
  visa: 'VISA',
}

type Props = {
  CARD_TYPES?: Record<string, any>
  cardCVCInputRenderer: Function
  cardExpiryInputRenderer: Function
  cardNumberInputRenderer: Function
  cardZipInputRenderer: Function
  onError?: (error?: string, inputName?: string) => void
  cardExpiryInputProps: Record<string, any>
  cardNumberInputProps: Record<string, any>
  cardCVCInputProps: Record<string, any>
  cardZipInputProps: Record<string, any>
  cardImageClassName: string
  cardImageStyle: Record<string, any>
  containerClassName: string
  containerStyle: Record<string, any>
  dangerTextClassName: string
  dangerTextStyle: Record<string, any>
  fieldClassName: string
  fieldStyle: Record<string, any>
  enableZipInput: boolean
  images?: Record<string, any>
  inputComponent: Function | Record<string, any> | string
  inputClassName: string
  inputStyle: Record<string, any>
  invalidClassName: string
  invalidStyle: Record<string, any>
  customTextLabels: Record<string, any>
  hideError?: boolean
}

type State = {
  cardImage: string
  cardNumberLength: number
  cardNumber?: string
  errorText?: string
  showZip: boolean
}

const inputRenderer = ({ inputComponent, props }: Record<string, any>) => {
  const Input = inputComponent || 'input'
  return <Input {...props} />
}

class CreditCardInput extends Component<Props, State> {
  cardExpiryField: any
  cardNumberField: any
  cvcField: any
  zipField: any
  CARD_TYPES: any
  images: any

  static defaultProps = {
    cardCVCInputProps: {},
    cardCVCInputRenderer: inputRenderer,
    cardExpiryInputProps: {},
    cardExpiryInputRenderer: inputRenderer,
    cardImageClassName: '',
    cardImageStyle: {},
    cardNumberInputProps: {},
    cardNumberInputRenderer: inputRenderer,
    cardZipInputProps: {},
    cardZipInputRenderer: inputRenderer,
    containerClassName: '',
    containerStyle: {},
    customTextLabels: {},
    dangerTextClassName: '',
    dangerTextStyle: {},
    enableZipInput: false,
    fieldClassName: '',
    fieldStyle: {},
    inputClassName: '',
    inputComponent: 'input',
    inputStyle: {},
    invalidClassName: 'is-invalid',
    invalidStyle: {},
    hideError: false
  }

  constructor(props: Props) {
    super(props)
    this.CARD_TYPES = Object.assign({}, CARD_TYPES, props.CARD_TYPES)
    this.images = Object.assign({}, images, props.images)
    this.state = {
      cardImage: this.images.placeholder,
      cardNumber: undefined,
      cardNumberLength: 0,
      errorText: undefined,
      showZip: false,
    }
  }

  componentDidMount = () => {
    this.setState({ cardNumber: this.cardNumberField.value }, () => {
      const cardType = valid.number(this.state.cardNumber ?? '')
      const images = this.images
      this.setState({
        cardImage: images[cardType.card?.type ?? ''] || images.placeholder,
      })
    })
  }

  isMonthDashKey = ({ key, target: { value } }: any = {}) => {
    return !value.match(/[/-]/) && /^[/-]$/.test(key)
  }

  checkIsNumeric = (e: any) => {
    if (!/^\d*$/.test(e.key)) {
      e.preventDefault()
    }
  }

  handleCardNumberBlur = ({ onBlur }: { onBlur?: any } = { onBlur: null }) => (e: any) => {
    const { customTextLabels } = this.props
    //Log.info('isValid', valid.number(e.target.value).isValid)
    if (!valid.number(e.target.value).isValid) {
      this.setFieldInvalid(customTextLabels.invalidCardNumber || 'Card number is invalid', 'cardNumber')
    }

    const { cardNumberInputProps } = this.props
    cardNumberInputProps.onBlur && cardNumberInputProps.onBlur(e)
    onBlur && onBlur(e)
  }

  handleCardNumberChange = ({ onChange }: { onChange?: any } = { onChange: null }) => (e: any) => {
    const { customTextLabels, enableZipInput, cardNumberInputProps } = this.props
    const images = this.images
    const cardNumber = e.target.value
    const cardNumberLength = cardNumber.split(' ').join('').length
    const cardType = valid.number(cardNumber).card?.type ?? ''
    const cardTypeInfo = creditCardType.getTypeInfo(creditCardType.types[this.CARD_TYPES[cardType]]) || {}
    const global = window as any
    global.arie = cardTypeInfo

    const getCardTypeLengths = (number: string): number[] => {
      return valid.number(number)?.card?.lengths ?? [16]
    }

    this.cardNumberField.value = formatCardNumber(cardNumber)

    this.setState({
      cardImage: images[cardType] || images.placeholder,
      cardNumber,
    })

    if (enableZipInput) {
      this.setState({ showZip: cardNumberLength >= 6 })
    }

    this.setFieldValid()
    const cardTypeLengths = getCardTypeLengths(this.state.cardNumber ?? '')
    const lastCardTypeLength = cardTypeLengths[cardTypeLengths.length - 1]

    const x = () => {
      for (const length of cardTypeLengths) {
        if (length === cardNumberLength && valid.number(cardNumber).isValid) {
          this.cardExpiryField.focus()
          return
        }
      }

      if (cardNumberLength === lastCardTypeLength) {
        this.setFieldInvalid(customTextLabels.invalidCardNumber || 'Card number is invalid', 'cardNumber')
      }
    }
    x()

    cardNumberInputProps.onChange && cardNumberInputProps.onChange(e)
    onChange && onChange(e)
  }

  handleCardNumberKeyPress = (e: any) => {
    const value = e.target.value
    this.checkIsNumeric(e)
    if (value && !isHighlighted()) {
      const valueLength = value.split(' ').join('').length
      if (hasCardNumberReachedMaxLength(value, valueLength)) {
        e.preventDefault()
      }
    }
  }

  handleCardExpiryBlur = ({ onBlur }: { onBlur?: any } = { onBlur: null }) => (e: any) => {
    const { customTextLabels } = this.props
    const cardExpiry = e.target.value.split(' / ').join('/')
    const expiryError = isExpiryInvalid(cardExpiry, customTextLabels.expiryError)
    if (expiryError) {
      this.setFieldInvalid(expiryError, 'cardExpiry')
    }

    const { cardExpiryInputProps } = this.props
    cardExpiryInputProps.onBlur && cardExpiryInputProps.onBlur(e)
    onBlur && onBlur(e)
  }

  handleCardExpiryChange = ({ onChange }: { onChange?: any } = { onChange: null }) => (e: any) => {
    const { customTextLabels } = this.props

    this.cardExpiryField.value = formatExpiry(e)
    const value = this.cardExpiryField.value.split(' / ').join('/')

    this.setFieldValid()

    const expiryError = isExpiryInvalid(value, customTextLabels.expiryError)
    if (value.length > 4) {
      if (expiryError) {
        this.setFieldInvalid(expiryError, 'cardExpiry')
      } else {
        this.cvcField.focus()
      }
    }

    const { cardExpiryInputProps } = this.props
    cardExpiryInputProps.onChange && cardExpiryInputProps.onChange(e)
    onChange && onChange(e)
  }

  handleCardExpiryKeyPress = (e: any) => {
    const value = e.target.value

    if (!this.isMonthDashKey(e)) {
      this.checkIsNumeric(e)
    }

    if (value && !isHighlighted()) {
      const valueLength = value.split(' / ').join('').length
      if (valueLength >= 4) {
        e.preventDefault()
      }
    }
  }

  handleCardCVCBlur = ({ onBlur }: { onBlur?: any } = { onBlur: null }) => (e: any) => {
    const { customTextLabels } = this.props
    if (!valid.cvv(e.target.value, valid.number(this.state.cardNumber ?? '').card?.code?.size ?? 3)) {
      this.setFieldInvalid(customTextLabels.invalidCvc || 'CVC is invalid', 'cardCVC')
    }

    const { cardCVCInputProps } = this.props
    cardCVCInputProps.onBlur && cardCVCInputProps.onBlur(e)
    onBlur && onBlur(e)
  }

  handleCardCVCChange = ({ onChange }: { onChange?: any } = { onChange: null }) => (e: any) => {
    const { customTextLabels } = this.props
    const value = formatCvc(e.target.value)
    this.cvcField.value = value
    const CVC = value
    const CVCLength = CVC.length
    const isZipFieldAvailable = this.props.enableZipInput && this.state.showZip
    const cardType = valid.number(this.state.cardNumber ?? '')?.card?.type ?? ''
    const maxCvcLength = valid.number(this.state.cardNumber ?? '')?.card?.code?.size ?? 3

    this.setFieldValid()
    if (CVCLength > maxCvcLength) {
      if (!valid.cvv(CVC, valid.number(this.state.cardNumber ?? '')?.card?.code?.size).isValid) {
        this.setFieldInvalid(customTextLabels.invalidCvc || 'CVC is invalid', 'cardCVC')
      }
    }

    if (isZipFieldAvailable && hasCVCReachedMaxLength(cardType, CVCLength)) {
      this.zipField.focus()
    }

    const { cardCVCInputProps } = this.props
    cardCVCInputProps.onChange && cardCVCInputProps.onChange(e)
    onChange && onChange(e)
  }

  handleCardCVCKeyPress = (e: any) => {
    const cardType = valid.number(this.state.cardNumber ?? '')?.card?.type
    const value = e.target.value
    this.checkIsNumeric(e)
    if (value && !isHighlighted()) {
      const valueLength = value.split(' / ').join('').length
      if (hasCVCReachedMaxLength(cardType ?? '', valueLength)) {
        e.preventDefault()
      }
    }
  }

  handleCardZipBlur = ({ onBlur }: { onBlur?: any } = { onBlur: null }) => (e: any) => {
    const { customTextLabels } = this.props
    if (!isZipValid(e.target.value)) {
      this.setFieldInvalid(customTextLabels.invalidZipCode || 'Zip code is invalid', 'cardZip')
    }

    const { cardZipInputProps } = this.props
    cardZipInputProps.onBlur && cardZipInputProps.onBlur(e)
    onBlur && onBlur(e)
  }

  handleCardZipChange = ({ onChange }: { onChange?: any } = { onChange: null }) => (e: any) => {
    const { customTextLabels } = this.props
    const zip = e.target.value
    const zipLength = zip.length

    this.setFieldValid()

    if (zipLength >= 5 && !isZipValid(zip)) {
      this.setFieldInvalid(customTextLabels.invalidZipCode || 'Zip code is invalid', 'cardZip')
    }

    const { cardZipInputProps } = this.props
    cardZipInputProps.onChange && cardZipInputProps.onChange(e)
    onChange && onChange(e)
  }

  handleCardZipKeyPress = (e: any) => {
    const cardType = valid.number(this.state.cardNumber ?? '')?.card?.type ?? ''
    const value = e.target.value
    this.checkIsNumeric(e)
    if (value && !isHighlighted()) {
      const valueLength = value.split(' / ').join('').length
      if (hasZipReachedMaxLength(cardType, valueLength)) {
        e.preventDefault()
      }
    }
  }

  handleKeyDown = (ref: any) => {
    return (e: any) => {
      if (e.keyCode === BACKSPACE_KEY_CODE && !e.target.value) {
        ref.focus()
      }
    }
  }

  setFieldInvalid = (errorText: string, inputName?: string) => {
    const { onError } = this.props

    if (inputName) {
      const { onError } = (this.props as any)[`${inputName}InputProps`]
      onError && onError(errorText)
    }

    onError?.(errorText, inputName)
  }

  setFieldValid = () => {
    const { onError } = this.props
    const { invalidClassName } = this.props
    document.getElementById('field-wrapper')?.classList.remove(invalidClassName)
    this.setState({ errorText: undefined })
    onError?.()
  }

  render = () => {
    const { cardImage, errorText, showZip } = this.state
    const {
      cardImageClassName,
      cardImageStyle,
      cardCVCInputProps,
      cardZipInputProps,
      cardExpiryInputProps,
      cardNumberInputProps,
      cardCVCInputRenderer,
      cardExpiryInputRenderer,
      cardNumberInputRenderer,
      cardZipInputRenderer,
      containerClassName,
      containerStyle,
      dangerTextClassName,
      dangerTextStyle,
      enableZipInput,
      fieldClassName,
      fieldStyle,
      inputClassName,
      inputComponent,
      inputStyle,
      invalidStyle,
      customTextLabels,
      hideError
    } = this.props

    console.log(`hideError: ${hideError}`)

    return (
      <Container className={containerClassName} styled={containerStyle}>
        <FieldWrapper className={fieldClassName} id="field-wrapper" invalidStyled={invalidStyle} styled={fieldStyle}>
          <CardImage className={cardImageClassName} src={cardImage} styled={cardImageStyle} />
          <InputWrapper data-max="9999 9999 9999 9999 9999" inputStyled={inputStyle} isActive translateX={false}>
            {cardNumberInputRenderer({
              handleCardNumberBlur: (onBlur: any) => this.handleCardNumberBlur({ onBlur }),
              handleCardNumberChange: (onChange: any) => this.handleCardNumberChange({ onChange }),
              inputComponent,
              props: {
                autoComplete: 'cc-number',
                className: `credit-card-input ${inputClassName}`,
                id: 'card-number',
                maxLength: '19',
                placeholder: customTextLabels.cardNumberPlaceholder || 'Card number',
                ref: (cardNumberField: any) => {
                  this.cardNumberField = cardNumberField
                },
                type: 'tel',
                ...cardNumberInputProps,
                onBlur: this.handleCardNumberBlur(),
                onChange: this.handleCardNumberChange(),
                onKeyPress: this.handleCardNumberKeyPress,
              },
            })}
          </InputWrapper>
          <InputWrapper data-max="MM / YY 9" inputStyled={inputStyle} isActive translateX={enableZipInput && !showZip}>
            {cardExpiryInputRenderer({
              handleCardExpiryBlur: (onBlur: any) => this.handleCardExpiryBlur({ onBlur }),
              handleCardExpiryChange: (onChange: any) => this.handleCardExpiryChange({ onChange }),
              inputComponent,
              props: {
                autoComplete: 'cc-exp',
                className: `credit-card-input ${inputClassName}`,
                id: 'card-expiry',
                placeholder: customTextLabels.expiryPlaceholder || 'MM/YY',
                ref: (cardExpiryField: any) => {
                  this.cardExpiryField = cardExpiryField
                },
                type: 'tel',
                ...cardExpiryInputProps,
                onBlur: this.handleCardExpiryBlur(),
                onChange: this.handleCardExpiryChange(),
                onKeyDown: this.handleKeyDown(this.cardNumberField),
                onKeyPress: this.handleCardExpiryKeyPress,
              },
            })}
          </InputWrapper>
          <InputWrapper data-max="99999" inputStyled={inputStyle} isActive translateX={enableZipInput && !showZip}>
            {cardCVCInputRenderer({
              handleCardCVCBlur: (onBlur: any) => this.handleCardCVCBlur({ onBlur }),
              handleCardCVCChange: (onChange: any) => this.handleCardCVCChange({ onChange }),
              inputComponent,
              props: {
                autoComplete: 'off',
                className: `credit-card-input ${inputClassName}`,
                id: 'cvc',
                maxLength: '5',
                placeholder: customTextLabels.cvcPlaceholder || 'CVC',
                ref: (cvcField: any) => {
                  this.cvcField = cvcField
                },
                type: 'tel',
                ...cardCVCInputProps,
                onBlur: this.handleCardCVCBlur(),
                onChange: this.handleCardCVCChange(),
                onKeyDown: this.handleKeyDown(this.cardExpiryField),
                onKeyPress: this.handleCardCVCKeyPress,
              },
            })}
          </InputWrapper>
          <InputWrapper
            data-max="999999"
            isActive={enableZipInput}
            isZipActive={showZip}
            translateX={enableZipInput && !showZip}
          >
            {cardZipInputRenderer({
              handleCardZipBlur: (onBlur: any) => this.handleCardZipBlur({ onBlur }),
              handleCardZipChange: (onChange: any) => this.handleCardZipChange({ onChange }),
              inputComponent,
              props: {
                className: `credit-card-input zip-input ${inputClassName}`,
                id: 'zip',
                maxLength: '6',
                pattern: '[0-9]*',
                placeholder: customTextLabels.zipPlaceholder || 'Zip',
                ref: (zipField: any) => {
                  this.zipField = zipField
                },
                type: 'text',
                ...cardZipInputProps,
                onBlur: this.handleCardZipBlur(),
                onChange: this.handleCardZipChange(),
                onKeyDown: this.handleKeyDown(this.cvcField),
                onKeyPress: this.handleCardZipKeyPress,
              },
            })}
          </InputWrapper>
        </FieldWrapper>
        {(!hideError && errorText) ? (
          <DangerText className={dangerTextClassName} styled={dangerTextStyle}>
            {errorText}
          </DangerText>
        ) : null}
      </Container>
    )
  }
}

export default CreditCardInput

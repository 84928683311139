import { CoinCloudApiWrapper, CoinCloudUserStat } from '@xylabs/sdk-coin-js'
import { useAsyncEffect } from '@xyo-network/sdk-xyo-react-js'
import { AxiosError } from 'axios'
import { useState } from 'react'

import { useAuthToken } from '../../../Contexts'

const useCloudDashboardStats = () => {
  const token = useAuthToken()

  const [stats, setStats] = useState<CoinCloudUserStat[] | null>()
  const [error, setError] = useState<AxiosError>()
  const [refreshCount, setRefreshCount] = useState(0)

  useAsyncEffect(
    async (mounted) => {
      const api = token ? CoinCloudApiWrapper.get({ auth: token }) : undefined
      if (api) {
        try {
          if (mounted()) {
            if (error) setError(undefined)
            setStats(undefined)
          }
          const result = await (refreshCount === 0 ? api.getUserStats() : api.fetchUserStats())
          if (mounted()) {
            setStats(result)
          }
        } catch (ex) {
          if (mounted()) {
            setError(ex)
          }
        }
      }
    },
    [token, refreshCount]
  )

  const refresh = () => {
    setRefreshCount(refreshCount + 1)
  }

  return { error, refresh, stats }
}

export default useCloudDashboardStats

import {
  Button,
  colors,
  IconButton,
  Step,
  StepContent,
  StepLabel,
  StepProps,
  TextField,
  Typography,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { useAuth } from '@xyo-network/sdk-coin-react-js/auth'
import { delay } from '@xyo-network/sdk-xyo-js'
import { FlexGrowCol, FlexGrowRow, FlexRow } from '@xyo-network/sdk-xyo-react-js'
import React, { ChangeEvent, useState } from 'react'

import { useSettings } from '../../../../Contexts'

interface PasswordStepProps extends StepProps {
  email?: string
  onBack?: () => void
  onContinue?: (email: string, password: string) => void
  stepName?: string
  subtitle?: string
}

const CustomStep: React.FC<PasswordStepProps> = (props) => {
  const {
    email,
    onBack,
    onContinue,
    stepName = 'Create a password',
    subtitle = "Just a few more steps and you're done! We hate forms, too.",
    ...stepProps
  } = props
  const [password, setPassword] = useState('')
  const [busy, setBusy] = useState(false)
  const [error, setError] = useState<string>()

  const auth = useAuth()
  const { developerMode } = useSettings()

  const onEmailFocus = () => {
    onBack?.()
  }

  const onPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }

  const onLocalContinue = async () => {
    if (email && password) {
      try {
        setBusy(true)
        delay(500)
        if (developerMode) {
          try {
            await auth?.signInWithEmailAndPassword(email, password)
            onContinue?.(email, password)
          } catch (ex) {
            console.log(`SignIn Failed: ${ex}`)
          }
        } else {
          await auth?.createUserWithEmailAndPassword(email, password)
          onContinue?.(email, password)
        }
      } catch (ex) {
        setError(ex.message)
        if (developerMode) {
          onContinue?.(email, password)
        }
      } finally {
        setBusy(false)
      }
    }
  }

  return (
    <Step {...stepProps}>
      <StepLabel>
        <Typography variant="h4">{stepName}</Typography>
      </StepLabel>
      <StepContent>
        <FlexGrowCol busy={busy} alignItems="start" paddingY={1}>
          <Typography variant="body1">{subtitle}</Typography>
          <FlexRow justifyContent="start" paddingLeft={1} paddingBottom={1} justifyItems={'flex-end'}>
            <FlexGrowCol alignItems="start">
              <Typography variant="caption" gutterBottom={false}>
                COIN Account Email
              </Typography>
              <Typography variant="body1" align="left" gutterBottom={false} style={{ color: colors.grey[700] }}>
                {email}
              </Typography>
            </FlexGrowCol>
            <FlexGrowCol alignItems="end" paddingLeft={1} justifyContent="flex-end">
              <IconButton aria-label="edit email" onClick={onEmailFocus} onMouseDown={onEmailFocus}>
                <EditIcon />
              </IconButton>
            </FlexGrowCol>
          </FlexRow>
          <TextField
            variant="outlined"
            label="Password"
            margin="dense"
            fullWidth
            type="password"
            value={password ?? ''}
            onChange={onPasswordChange}
            autoFocus
            autoComplete="off"
          />
          <Button disabled={!password} fullWidth variant="contained" onClick={onLocalContinue} color="secondary">
            Continue
          </Button>
          <FlexGrowRow justifyContent="flex-start" margin={1}>
            <Typography variant="caption" color="error">
              {error ?? <span>&nbsp;</span>}
            </Typography>
          </FlexGrowRow>
        </FlexGrowCol>
      </StepContent>
    </Step>
  )
}

export default CustomStep

import { useContext } from 'react'

import Context from './Context'

const useApi = (requireAuth = false) => {
  const { api } = useContext(Context)
  if (requireAuth) {
    if (!api?.authenticated) {
      return
    }
  }
  return api
}

export default useApi

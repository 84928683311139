import { BoxProps, Typography, useTheme } from '@material-ui/core'
import { FlexCol } from '@xyo-network/sdk-xyo-react-js'
import React from 'react'

interface Props extends BoxProps {
  image?: string
  textZoom?: number
  titleAlign?: 'left' | 'right' | 'center' | 'inherit'
}

const Hero: React.FC<Props> = (props) => {
  const theme = useTheme()
  const {
    children,
    image,
    title = 'Welcome to COIN!',
    alignItems,
    textZoom = 1,
    titleAlign = 'left',
    bgcolor = theme.palette.background.paper,
    style,
    ...boxProps
  } = props
  return (
    <FlexCol
      bgcolor={bgcolor}
      alignItems={alignItems}
      justifyContent="flex-start"
      {...boxProps}
      color={theme.palette.getContrastText(`${bgcolor}`)}
      style={{
        ...style,
        backgroundImage: `url(${image})`,
        backgroundPosition: 'bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% auto',
      }}
    >
      <FlexCol margin={4} alignItems={alignItems} style={{ zoom: textZoom }}>
        <Typography variant="h1" align={titleAlign}>
          {title}
        </Typography>
      </FlexCol>
      {children}
    </FlexCol>
  )
}

export default Hero

import { BoxProps, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { useUserEvents } from '@xyo-network/sdk-coin-react-js'
import { isAuthenticated, useAuthUser } from '@xyo-network/sdk-coin-react-js/auth'
import { FlexCol, FlexGrowCol, FlexGrowRow, FlexRow, InvertableThemeProvider } from '@xyo-network/sdk-xyo-react-js'
import React, { useEffect } from 'react'

import BasePage from '../BasePage'
import coinScene from './img/coin-scene.svg'
import { CreateAccountInteractionArea, CreateAccountSuccessInteractionArea } from './InteractionArea'

const TwoColumnHero: React.FC<BoxProps> = (props) => {
  const theme = useTheme()
  const { bgcolor = theme.palette.background.paper, style, ...boxProps } = props
  return (
    <FlexCol
      bgcolor={bgcolor}
      alignItems="center"
      justifyContent="flex-start"
      {...boxProps}
      color={theme.palette.getContrastText(`${bgcolor}`)}
      style={{
        ...style,
        backgroundImage: `url(${coinScene})`,
        backgroundPosition: 'bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% auto',
      }}
    >
      <FlexRow margin={1} style={{ zoom: 2 }}>
        <Typography variant="h1" align="center">
          Welcome to COIN
        </Typography>
      </FlexRow>
    </FlexCol>
  )
}

const OneColumnHero: React.FC<BoxProps> = (props) => {
  const theme = useTheme()
  const { bgcolor = theme.palette.background.paper, style, ...boxProps } = props
  return (
    <FlexCol
      bgcolor={bgcolor}
      alignItems="flex-start"
      justifyContent="flex-start"
      {...boxProps}
      color={theme.palette.getContrastText(`${bgcolor}`)}
      style={{
        ...style,
        backgroundImage: `url(${coinScene})`,
        backgroundPosition: 'bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% auto',
      }}
    >
      <FlexCol margin={1} alignItems="flex-start" style={{ zoom: 1.4 }}>
        <Typography variant="h1" align="center">
          Welcome to COIN
        </Typography>
      </FlexCol>
    </FlexCol>
  )
}

interface Props {
  funnelName: string
}

const Page: React.FC<Props> = (props) => {
  const { funnelName } = props
  const userEvents = useUserEvents()
  const theme = useTheme()
  const singleColumn = useMediaQuery(theme.breakpoints.up('lg'))
  const authenticated = isAuthenticated()
  const user = useAuthUser()

  useEffect(() => {
    userEvents.viewContent({ name: funnelName, path: document.location.href })
  }, [userEvents, funnelName])

  if (authenticated === undefined) {
    return <BasePage container="xl" disableGutters hideAppBar />
  }

  return (
    <BasePage hideAppBar>
      {singleColumn ? (
        <FlexGrowRow alignItems="stretch" minHeight="90vh">
          <InvertableThemeProvider dark={true}>
            <TwoColumnHero flexGrow={1} flexBasis={50} padding={2} />
          </InvertableThemeProvider>
          {authenticated ? (
            <CreateAccountSuccessInteractionArea sku={funnelName} email={user?.email ?? ''} />
          ) : (
            <CreateAccountInteractionArea margin={4} />
          )}
        </FlexGrowRow>
      ) : (
        <FlexGrowCol alignItems="stretch" minHeight="90vh">
          <InvertableThemeProvider dark={true}>
            <OneColumnHero paddingLeft={0.5} minHeight={260} />
          </InvertableThemeProvider>
          {authenticated ? (
            <CreateAccountSuccessInteractionArea sku={funnelName} email={user?.email ?? ''} />
          ) : (
            <CreateAccountInteractionArea margin={2} />
          )}
        </FlexGrowCol>
      )}
    </BasePage>
  )
}

export default Page

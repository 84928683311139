import { BoxProps } from '@material-ui/core'
import { FlexGrowRow } from '@xyo-network/sdk-xyo-react-js'
import React from 'react'

import { AccountHero } from '../../../../Components'
import blueCurve from '../../img/blue-curve.svg'
import desktopImage from '../../img/ps5-desktop.png'
import HeroContent from './HeroContent'

const Hero: React.FC<BoxProps> = (props) => {
  return (
    <AccountHero image={blueCurve} title="New User Special Offer" {...props}>
      <HeroContent marginX={3} />
      <FlexGrowRow
        position="absolute"
        bottom={0}
        right={0}
        height="50%"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <img src={desktopImage} width="auto" height="100%" />
      </FlexGrowRow>
    </AccountHero>
  )
}

export default Hero

import { Button, Step, StepContent, StepLabel, StepProps, Typography } from '@material-ui/core'
import { FlexGrowCol, FlexGrowRow, FlexRow } from '@xyo-network/sdk-xyo-react-js'
import React from 'react'

import AccountSource from '../AccountSource'

interface Props extends StepProps {
  onContinue?: (source: AccountSource) => void
  stepName?: string
  subtitle?: string
}

const CustomStep: React.FC<Props> = (props) => {
  const { onContinue, stepName = 'Source', subtitle = '', ...stepProps } = props

  const onLocalContinue = (source: AccountSource) => {
    onContinue?.(source)
  }

  return (
    <Step {...stepProps}>
      <StepLabel>
        <Typography variant="h4">{stepName}</Typography>
      </StepLabel>
      <StepContent>
        <FlexGrowCol alignItems="start" padding={1}>
          <Typography variant="body1">{subtitle}</Typography>
          <FlexRow flexWrap="wrap" justifyContent="flex-start">
            <FlexGrowRow margin={1}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={() => onLocalContinue(AccountSource.AppStore)}
              >
                <Typography variant="button" noWrap>
                  Apple App Store
                </Typography>
              </Button>
            </FlexGrowRow>
            <FlexGrowRow margin={1}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={() => onLocalContinue(AccountSource.PlayStore)}
              >
                <Typography variant="button" noWrap>
                  Google Play Store
                </Typography>
              </Button>
            </FlexGrowRow>
            <FlexGrowRow margin={1}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={() => onLocalContinue(AccountSource.FacebookAd)}
              >
                <Typography variant="button" noWrap>
                  Facebook Ad
                </Typography>
              </Button>
            </FlexGrowRow>
            <FlexRow margin={1}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={() => onLocalContinue(AccountSource.Other)}
              >
                <Typography variant="button" noWrap>
                  Other
                </Typography>
              </Button>
            </FlexRow>
          </FlexRow>
        </FlexGrowCol>
      </StepContent>
    </Step>
  )
}

export default CustomStep

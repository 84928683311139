import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import DetailsPage from './DetailsPage'
import { SigninPage } from './Signin'
import SignoutPage from './SignoutPage'
import { SignupPage } from './Signup'

const AccountPages: React.FC = () => {
  return (
    <Switch>
      <Route component={DetailsPage} exact path="/account" />
      <Route component={SigninPage} exact path="/account/signin" />
      <Route component={SignoutPage} exact path="/account/signout" />
      <Route component={SignupPage} exact path="/account/signup" />
      <Route>
        <Redirect to="/account" />
      </Route>
    </Switch>
  )
}

export default AccountPages

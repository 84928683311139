import axios from 'axios'
import * as EmailValidator from 'email-validator'

import { API_KEY, API_URL } from './api'

enum DebounceCode {
  AcceptAll = '4',
  Deliverable = '5',
  Disposable = '3',
  Invalid = '6',
  Role = '8',
  SpamTrap = '2',
  Syntax = '1',
  Unknown = '7',
}

interface DebounceResult {
  balance?: string
  debounce?: {
    code?: DebounceCode
    did_you_mean?: string
    email?: string
    error?: string
    free_email?: string
    reason?: string
    result?: string
    role?: string
    send_transactional?: string
  }
  success?: string
}

const cache = new Map<string, DebounceResult>()

const checkSingleEmail = async (email: string): Promise<DebounceResult> => {
  if (!EmailValidator.validate(email)) {
    return { debounce: { code: DebounceCode.Syntax, send_transactional: '0' }, success: '1' }
  }
  if (!cache.has(email)) {
    try {
      const response = await axios.get(`${API_URL}?api=${API_KEY}&email=${email}`)
      if (response.data) {
        cache.set(email, response.data)
      }
    } catch (ex) {
      if (ex.response?.status === 400 && ex.response?.data) {
        cache.set(email, ex.response.data)
      }
    }
  }
  //if the call is failing, assume we should accept it (send_transactional)
  const returnValue = cache.get(email) ?? { debounce: { send_transactional: '1' }, success: '0' }
  return returnValue
}

export { checkSingleEmail, DebounceCode }

import { CoinApiWrapper } from '@xylabs/sdk-coin-js'
import { useAuthUser } from '@xyo-network/sdk-coin-react-js/auth'
import { ApiStage } from '@xyo-network/sdk-xyo-js'
import { isLocalhost, useAsyncEffect } from '@xyo-network/sdk-xyo-react-js'
import React, { PropsWithChildren, useState } from 'react'

import ApiContext from './Context'

const Provider: React.FC<PropsWithChildren<unknown>> = (props) => {
  const { children } = props
  const user = useAuthUser()
  const apiStage = isLocalhost
    ? ApiStage.Local
    : document.location.hostname.startsWith('beta')
    ? ApiStage.Beta
    : ApiStage.Prod

  const apiDomain = isLocalhost
    ? 'http://localhost:3030/dev'
    : document.location.hostname.startsWith('beta')
    ? 'https://beta-api.coinapp.co'
    : 'https://api.coinapp.co'

  const [api, setApi] = useState<CoinApiWrapper>()
  const [refreshCount, setRefreshCount] = useState(0)
  const [error, setError] = useState<Error>()

  const refreshAll = () => {
    setError(undefined)
    setApi(undefined)
    setRefreshCount(refreshCount + 1)
  }

  useAsyncEffect(
    async (isMounted) => {
      const token = await user?.getIdToken()
      if (apiDomain) {
        const api = CoinApiWrapper.get({ apiDomain, token })
        try {
          if (isMounted()) {
            setApi(api)
          }
        } catch (ex) {
          if (isMounted()) {
            setError(ex)
          }
        }
      }
    },
    [apiDomain, refreshCount, user]
  )

  return (
    <ApiContext.Provider
      value={{
        api,
        apiDomain,
        apiStage,
        error,
        refreshAll,
      }}
    >
      {api !== undefined ? children : null}
    </ApiContext.Provider>
  )
}

export default Provider

import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import RulesPage from './Rules'

const Pages: React.FC = () => {
  return (
    <Switch>
      <Route component={RulesPage} exact path="/legal/rules" />
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  )
}

export default Pages

import offerImage from './img/sweepstakes/fryer/320x180.jpg'
import SweepstakesBasePage from './SweepstakesBasePage'

const Page: React.FC = () => {
  return (
    <SweepstakesBasePage
      optionsId={''}
      funnelName="fryer"
      offerImage={offerImage}
      offerTitle="COSORI Smart Air Fryer"
    />
  )
}

export default Page

enum AccountSource {
  AppStore = 'app-store',
  FacebookAd = 'facebook-ad',
  Friend = 'friend',
  GoogleAd = 'google-ad',
  Other = 'other',
  OtherAd = 'other-ad',
  PlayStore = 'play-store',
}

export default AccountSource

import { BoxProps, Typography } from '@material-ui/core'
import { useUserEvents } from '@xyo-network/sdk-coin-react-js'
import { ButtonEx, Experiment, Experiments, FlexCol, FlexRow } from '@xyo-network/sdk-xyo-react-js'
import React from 'react'

import instructionsImage from './img/phone-sweepstakes-instructions.png'

interface SuccessBoxProps extends BoxProps {
  email: string
  sku: string
}

const SuccessBox: React.FC<SuccessBoxProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { email, sku, title = 'Success!', ...boxProps } = props
  const userEvents = useUserEvents()

  const onInstallClick = () => {
    userEvents.installCompleted()
    userEvents.funnelCompleted()
    window.open('https://coin.onelink.me/ePJg/120ff56c')
  }

  return (
    <FlexCol alignItems="stretch" justifyContent="flex-start" {...boxProps}>
      <FlexRow margin={1} justifyContent="flex-start">
        <Typography variant="h3">{title}</Typography>
      </FlexRow>
      <FlexRow margin={1} justifyContent="flex-start">
        <Typography variant="h5">How do I activate my entries?</Typography>
      </FlexRow>
      <FlexRow margin={1} justifyContent="flex-start">
        <Typography variant="body1">
          To activate your sweepstakes, you'll need to <strong>download COIN App</strong>. Inside the app, you can apply
          your sweepstakes to the Playstation 5 Sweepstakes or any other Sweepstakes you are interested in!
        </Typography>
      </FlexRow>
      <FlexRow margin={1} justifyContent="flex-start">
        <ButtonEx fullWidth variant="contained" color="secondary" onClick={onInstallClick}>
          Download COIN
        </ButtonEx>
      </FlexRow>
      <FlexRow margin={1} justifyContent="flex-start">
        <Typography variant="body1">
          The button will take you to whatever app store is on your device. COIN is available for iOS and for Android!
        </Typography>
      </FlexRow>
      <FlexRow margin={1} justifyContent="flex-start">
        <Typography variant="body1">If you are on desktop, we'll take you to the COIN Website.</Typography>
      </FlexRow>
      <Experiments name="instruction-images">
        <Experiment key="visible" weight={50}>
          <FlexRow>
            <img src={instructionsImage} width="100%" height="auto" />
          </FlexRow>
        </Experiment>
        <Experiment key="hidden" weight={50}>
          <FlexRow></FlexRow>
        </Experiment>
      </Experiments>
    </FlexCol>
  )
}

export default SuccessBox

import { Link, Typography } from '@material-ui/core'
import {
  CoinFeedbackSubmissionJson,
  CoinFeedbackSubmissionStatus,
  FeedbackOffers,
  FeedbackOfferSku,
} from '@xylabs/sdk-coin-js'
import { ButtonEx, FlexGrowCol, FlexRow } from '@xyo-network/sdk-xyo-react-js'
import { WithId } from 'mongodb'
import { useState } from 'react'

import { useApi } from '../../../Contexts'

interface Props {
  feedback: WithId<CoinFeedbackSubmissionJson>
  filter?: CoinFeedbackSubmissionStatus
}

const FeedbackViewer: React.FC<Props> = (props) => {
  const { feedback, filter } = props
  const [busy, setBusy] = useState(false)
  const api = useApi()

  const sku = feedback.sku as FeedbackOfferSku

  const offer = feedback.sku ? FeedbackOffers[sku] : undefined

  const setStatus = async (status: CoinFeedbackSubmissionStatus) => {
    setBusy(true)
    await api?.updateFeedbackStatus({
      _id: feedback._id,
      status: status,
    })
    feedback.status = status
    if (status === CoinFeedbackSubmissionStatus.Approved) {
      window.open(`https://coinadmin.firebaseapp.com/#/user/${feedback.uid}`)
    }
    setBusy(false)
  }

  if (filter && filter !== CoinFeedbackSubmissionStatus.All && filter !== feedback.status) {
    return null
  }

  return (
    <FlexGrowCol busy={busy}>
      <FlexRow>
        <Link href={`mailto:${feedback.email}`} target="_blank">
          <Typography variant="body1">{`Email: ${feedback.email}`}</Typography>
        </Link>
      </FlexRow>
      <FlexRow>
        {feedback.files?.map((file: Buffer | string, index: number) => {
          const fileName = file as string
          const imageUrl = `https://image.app.coinapp.co/${fileName}`
          return (
            <img
              onClick={() => window.open(imageUrl)}
              key={index}
              height="auto"
              width={320}
              src={imageUrl}
              style={{ cursor: 'pointer' }}
            />
          )
        })}
      </FlexRow>
      <FlexRow>
        {offer?.reward.coin ? (
          <FlexRow>
            <Typography variant="h5">{`Reward: ${offer.reward.coin} COIN`}</Typography>
          </FlexRow>
        ) : null}
        {offer?.reward.sweepstakes ? (
          <FlexRow>
            <Typography variant="h5">{`Reward: ${offer.reward.sweepstakes} Entries`}</Typography>
          </FlexRow>
        ) : null}
      </FlexRow>
      <FlexRow>
        <Typography variant="body1">{`Sku: ${feedback.sku}`}</Typography>
      </FlexRow>
      <FlexRow>
        <Typography variant="body1">{`Status: ${feedback.status}`}</Typography>
      </FlexRow>
      <FlexRow>
        <ButtonEx margin={1} variant="outlined" onClick={() => setStatus(CoinFeedbackSubmissionStatus.Deleted)}>
          Delete
        </ButtonEx>
        <ButtonEx margin={1} variant="outlined" onClick={() => setStatus(CoinFeedbackSubmissionStatus.Flagged)}>
          Flag
        </ButtonEx>
        <ButtonEx margin={1} variant="outlined" onClick={() => setStatus(CoinFeedbackSubmissionStatus.Incomplete)}>
          Incomplete
        </ButtonEx>
        <ButtonEx margin={1} variant="outlined" onClick={() => setStatus(CoinFeedbackSubmissionStatus.Disapproved)}>
          Disapprove
        </ButtonEx>
        <ButtonEx
          margin={1}
          variant="contained"
          color="secondary"
          onClick={() => setStatus(CoinFeedbackSubmissionStatus.Approved)}
        >
          Approve
        </ButtonEx>
      </FlexRow>
    </FlexGrowCol>
  )
}

export default FeedbackViewer

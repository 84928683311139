import { CardActions, Checkbox, FormControl, FormControlLabel, Link, Typography } from '@material-ui/core'
import { ButtonEx, FlexGrowCol, FlexRow } from '@xyo-network/sdk-xyo-react-js'
import { useState } from 'react'

import OfferProps from './SweepstakeProps'

const ActiveCardActions: React.FC<OfferProps> = (props) => {
  const { sweepstake } = props
  const { details } = sweepstake ?? {}

  const [quantity, setQuantity] = useState(25000)
  const [allowed, setAllowed] = useState(false)
  const [agreed, setAgreed] = useState(false)

  const onGetMoreClick = () => {
    alert(`Sorry, ${details?.title} not yet available`)
  }

  return (
    <CardActions
      disableSpacing={true}
      style={{
        display: 'flex',
        justifyContent: 'stretch',
      }}
    >
      <FlexGrowCol alignItems="stretch">
        <FlexRow justifyContent="space-around">
          <ButtonEx
            variant="contained"
            size="small"
            onClick={() => setQuantity(5000)}
            color={quantity === 5000 ? 'secondary' : 'inherit'}
          >
            5,000
          </ButtonEx>
          <ButtonEx
            variant="contained"
            size="small"
            onClick={() => setQuantity(10000)}
            color={quantity === 10000 ? 'secondary' : 'inherit'}
          >
            10,000
          </ButtonEx>
          <ButtonEx
            variant="contained"
            size="small"
            onClick={() => setQuantity(25000)}
            color={quantity === 25000 ? 'secondary' : 'inherit'}
          >
            25,000
          </ButtonEx>
        </FlexRow>
        <FlexRow>
          <FormControl>
            <FormControlLabel
              control={<Checkbox checked={allowed} onChange={(event) => setAllowed(event.target.checked)} />}
              label={
                <Typography color="textSecondary" variant="body1">
                  I am an age 18+ US/UK Resident
                </Typography>
              }
            />
          </FormControl>
        </FlexRow>
        <FlexRow>
          <FormControl>
            <FormControlLabel
              control={<Checkbox checked={agreed} onChange={(event) => setAgreed(event.target.checked)} />}
              label={
                <Typography color="textSecondary" variant="body1">
                  I agree to the{' '}
                  <Link href="https://my.coinapp.co/sweepstakes-terms" target="_blank">
                    {'Terms & Conditions'}
                  </Link>
                </Typography>
              }
            />
          </FormControl>
        </FlexRow>
        <ButtonEx
          disabled={!(allowed && agreed)}
          color="secondary"
          variant="contained"
          size="small"
          margin={1}
          marginX={2}
          onClick={onGetMoreClick}
        >
          Get More
        </ButtonEx>
        <ButtonEx
          color="inherit"
          variant="outlined"
          size="small"
          margin={1}
          marginX={2}
          onClick={() => window.open('https://support.coinapp.co/hc/en-us/articles/360058411214')}
        >
          How to enter for free
        </ButtonEx>
      </FlexGrowCol>
    </CardActions>
  )
}

export default ActiveCardActions

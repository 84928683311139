import { BoxProps } from '@material-ui/core'
import { useUserEvents } from '@xyo-network/sdk-coin-react-js'
import React from 'react'

import AccountSuccessBox from './AccountSuccessBox'

interface SuccessBoxProps extends BoxProps {
  email: string
  sku: string
}

const SuccessBox: React.FC<SuccessBoxProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { sku, email, title = 'Success!', ...rootProps } = props

  const userEvents = useUserEvents()

  const onDownloadClick = () => {
    userEvents.installCompleted()
    userEvents.funnelCompleted()
    return false
  }

  return <AccountSuccessBox title={title} onDownloadClick={onDownloadClick} {...rootProps} />
}

export default SuccessBox

import { BoxProps } from '@material-ui/core'
import { FlexCol, FlexGrowRow, FlexRow } from '@xyo-network/sdk-xyo-react-js'
import React from 'react'

import { AccountCreateSuccessBox } from '../../../Components'
import logo from '../img/logo-full.svg'

interface Props extends BoxProps {
  email: string
  sku: string
}

const Success: React.FC<Props> = (props) => {
  const { sku, email, ...rootProps } = props
  return (
    <FlexCol flexGrow={1} flexBasis={50} alignItems="stretch" padding={2} {...rootProps}>
      <FlexRow justifyContent="flex-start" margin={1}>
        <img src={logo} title="COIN Logo" height={48} />
      </FlexRow>
      <AccountCreateSuccessBox sku={sku} email={email} />
      <FlexGrowRow />
    </FlexCol>
  )
}

export default Success

import { Footer } from '@xyo-network/sdk-coin-react-js'
import { FlexGrowRow } from '@xyo-network/sdk-xyo-react-js'

import About from './About'
import GetStarted from './GetStarted'

const AppFooter: React.FC = () => {
  return (
    <Footer menus={false}>
      <FlexGrowRow flexWrap="wrap" justifyContent="space-between" alignItems="flex-start" width="100%">
        <About marginX={2} alignItems="flex-start" />
        <FlexGrowRow />
        <GetStarted marginX={2} alignItems="flex-end" />
      </FlexGrowRow>
    </Footer>
  )
}

export default AppFooter

import offerImage from './img/sweepstakes/xyo-tokens/320x180.jpg'
import SweepstakesBasePage from './SweepstakesBasePage'

const Page: React.FC = () => {
  return (
    <SweepstakesBasePage optionsId={''} funnelName="xyo" offerImage={offerImage} offerTitle="1 Million XYO Tokens" />
  )
}

export default Page

import { Button, Step, StepContent, StepLabel, StepProps, TextField, Typography } from '@material-ui/core'
import { delay } from '@xyo-network/sdk-xyo-js'
import { FlexGrowCol, FlexGrowRow } from '@xyo-network/sdk-xyo-react-js'
import React, { ChangeEvent, KeyboardEvent, useState } from 'react'

import { checkSingleEmail, DebounceCode } from '../../../../lib'

interface EmailStepProps extends StepProps {
  onContinue?: (email: string) => void
  stepName?: string
  subtitle?: string
}

const CustomStep: React.FC<EmailStepProps> = (props) => {
  const { onContinue, stepName = 'Get Started', subtitle = '', ...stepProps } = props
  const [email, setEmail] = useState('')
  const [busy, setBusy] = useState(false)
  const [error, setError] = useState<string>()

  const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setError(undefined)
    setEmail(event.target.value)
  }

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === '+') {
      event.preventDefault()
    }
  }

  const onLocalContinue = async () => {
    setBusy(true)
    const result = (await Promise.all([checkSingleEmail(email), delay(500)]))[0]
    setBusy(false)
    if (result.debounce?.error) {
      setError(result.debounce?.error)
    } else if (result.debounce?.send_transactional === '1') {
      onContinue?.(email)
    } else {
      switch (result.debounce?.code) {
        case DebounceCode.Disposable:
          setError('Disposable emails are not allowed.')
          break
        case DebounceCode.Invalid:
          setError('We could not reach that email.  Please try a different email.')
          break
        case DebounceCode.Syntax:
          setError('That does not seem to be a valid email.')
          break
        default:
          setError(`Email Error [${result.debounce?.code}]`)
          break
      }
    }
  }

  return (
    <Step {...stepProps}>
      <StepLabel>
        <Typography variant="h4">{stepName}</Typography>
      </StepLabel>
      <StepContent>
        <FlexGrowCol busy={busy} alignItems="start" paddingY={1}>
          <Typography variant="body1">{subtitle}</Typography>
          <TextField
            variant="outlined"
            margin="dense"
            label="Email address"
            type="email"
            fullWidth
            onChange={onEmailChange}
            onKeyDown={onKeyDown}
            value={email ?? ''}
            autoFocus
            autoComplete="email"
          />
          <Button fullWidth variant="contained" onClick={onLocalContinue} color="secondary">
            Continue
          </Button>
          <FlexGrowRow justifyContent="flex-start" margin={1}>
            <Typography variant="caption" color="error">
              {error ?? <span>&nbsp;</span>}
            </Typography>
          </FlexGrowRow>
        </FlexGrowCol>
      </StepContent>
    </Step>
  )
}

export default CustomStep

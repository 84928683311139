import { Box, BoxProps, Link } from '@material-ui/core'
import { HoverScale } from '@xyo-network/sdk-xyo-react-js'
import React from 'react'

import iosDownload from './img/download-on-the-app-store-apple.svg'
import androidDownload from './img/get-it-on-google-play.svg'

interface DownloadButtonBoxProps extends BoxProps {
  android?: string
  androidLink?: string
  ios?: string
  iosLink?: string
}

const DownloadButtonBox: React.FC<DownloadButtonBoxProps> = (props) => {
  const {
    android = true,
    ios = true,
    androidLink = 'https://coin.onelink.me/ePJg/5d978f09',
    iosLink = 'https://coin.onelink.me/ePJg/76594177',
    ...boxProps
  } = props
  const { flexDirection = 'row' } = props
  const paddingX = flexDirection === 'row' ? 1 : 0

  return (
    <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center" {...boxProps}>
      <Box display={ios ? 'flex' : 'none'} flexShrink={1} paddingX={paddingX}>
        <HoverScale>
          <Link href={iosLink}>
            <img alt="Available on the AppStore" height="45" src={iosDownload} />
          </Link>
        </HoverScale>
      </Box>
      <Box display={android ? 'flex' : 'none'} flexShrink={1} paddingX={paddingX}>
        <HoverScale>
          <Link href={androidLink}>
            <img alt="Get it on Google Play" height="45" src={androidDownload} />
          </Link>
        </HoverScale>
      </Box>
    </Box>
  )
}

export default DownloadButtonBox

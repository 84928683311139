import { Button, Step, StepContent, StepLabel, StepProps, Typography } from '@material-ui/core'
import { FlexGrowCol, FlexRow } from '@xyo-network/sdk-xyo-react-js'
import React from 'react'

import { DownloadButtonBox } from '../../../../Components'

interface Props extends StepProps {
  link?: string
  onContinue?: () => void
  stepName?: string
  subtitle?: string
}

const CustomStep: React.FC<Props> = (props) => {
  const {
    onContinue,
    stepName = 'Get FREE Entries',
    subtitle = 'Inside the COIN App, you will get 1000 free COIN (10 COIN per entry = 100 entries)',
    link = 'https://coin.onelink.me/ePJg/a377b10a',
    ...stepProps
  } = props
  const onLocalContinue = () => {
    onContinue?.()
  }
  return (
    <Step {...stepProps}>
      <StepLabel>
        <Typography variant="h4">{stepName}</Typography>
      </StepLabel>
      <StepContent>
        <FlexGrowCol alignItems="start" paddingY={1}>
          <Typography variant="body1">{subtitle}</Typography>
          <FlexRow justifyContent="flex-start">
            <DownloadButtonBox iosLink={link} androidLink={link}></DownloadButtonBox>
          </FlexRow>
          <Typography variant="body1">
            <b>IMPORTANT: </b>Make sure to use the same email when signing into the app
          </Typography>
          <Button fullWidth variant="contained" onClick={onLocalContinue} color="secondary">
            Finish!
          </Button>
        </FlexGrowCol>
      </StepContent>
    </Step>
  )
}

export default CustomStep

import { Button, Step, StepContent, StepLabel, StepProps, Typography } from '@material-ui/core'
import { FlexGrowCol, FlexGrowRow, FlexRow } from '@xyo-network/sdk-xyo-react-js'
import React from 'react'

interface Props extends StepProps {
  onContinue?: (score: number) => void
  stepName?: string
  subtitle?: string
}

const CustomStep: React.FC<Props> = (props) => {
  const { onContinue, stepName = 'Score', subtitle = '', ...stepProps } = props

  const onLocalContinue = (score: number) => {
    onContinue?.(score)
  }

  return (
    <Step {...stepProps}>
      <StepLabel>
        <Typography variant="h4">{stepName}</Typography>
      </StepLabel>
      <StepContent>
        <FlexGrowCol alignItems="stretch" padding={1}>
          <Typography variant="body1">{subtitle}</Typography>
          <FlexRow justifyContent="flex-start">
            <FlexGrowRow margin={1}>
              <Button fullWidth variant="contained" color="secondary" onClick={() => onLocalContinue(5)}>
                Yes
              </Button>
            </FlexGrowRow>
            <FlexGrowRow margin={1}>
              <Button fullWidth variant="contained" color="secondary" onClick={() => onLocalContinue(1)}>
                No
              </Button>
            </FlexGrowRow>
          </FlexRow>
        </FlexGrowCol>
      </StepContent>
    </Step>
  )
}

export default CustomStep

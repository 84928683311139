import offerImage from './img/sweepstakes/samsung-65-inch/320x180.jpg'
import SweepstakesBasePage from './SweepstakesBasePage'

const Page: React.FC = () => {
  return (
    <SweepstakesBasePage optionsId={''} funnelName="samsung" offerImage={offerImage} offerTitle="Samsung 65 Inch TV" />
  )
}

export default Page

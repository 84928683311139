import React, { ReactElement, useState } from 'react'

import Context from './Context'

interface AppSettingsLoaderProps {
  children?: ReactElement[] | ReactElement | undefined
}

const LocalStoragePrefix = 'CoinAppSetting|'

enum Settings {
  DarkMode = 'DarkMode',
  DenseMode = 'DenseMode',
  DeveloperMode = 'DeveloperMode',
}

const Provider: React.FC<AppSettingsLoaderProps> = (props) => {
  const [darkMode, setDarkMode] = useState<boolean>(
    localStorage.getItem(`${LocalStoragePrefix}${Settings.DarkMode}`) === 'true'
  )

  const [denseMode, setDenseMode] = useState<boolean>(
    localStorage.getItem(`${LocalStoragePrefix}${Settings.DenseMode}`) === 'true'
  )

  const [developerMode, setDeveloperMode] = useState<boolean>(
    localStorage.getItem(`${LocalStoragePrefix}${Settings.DeveloperMode}`) === 'true'
  )

  const enableDarkMode = (enabled: boolean) => {
    localStorage.setItem(`${LocalStoragePrefix}${Settings.DarkMode}`, enabled ? 'true' : 'false')
    setDarkMode(enabled)
  }

  const enableDenseMode = (enabled: boolean) => {
    localStorage.setItem(`${LocalStoragePrefix}${Settings.DenseMode}`, enabled ? 'true' : 'false')
    setDenseMode(enabled)
  }

  const enableDeveloperMode = (enabled: boolean) => {
    localStorage.setItem(`${LocalStoragePrefix}${Settings.DeveloperMode}`, enabled ? 'true' : 'false')
    setDeveloperMode(enabled)
  }

  return (
    <Context.Provider
      value={{
        darkMode,
        denseMode,
        developerMode,
        enableDarkMode,
        enableDenseMode,
        enableDeveloperMode,
      }}
      {...props}
    />
  )
}

export default Provider

import { BoxProps, Link, Typography } from '@material-ui/core'
import { isAuthenticated } from '@xyo-network/sdk-coin-react-js/auth'
import { FlexCol, FlexRow } from '@xyo-network/sdk-xyo-react-js'
import { Link as RouterLink } from 'react-router-dom'

const CreateAccountRow: React.FC = () => {
  return (
    <FlexRow padding={1}>
      <Link component={RouterLink} to="/account/signup" variant="body2" color="inherit">
        Sign Up
      </Link>
    </FlexRow>
  )
}

const MyAccountRow: React.FC = () => {
  return (
    <FlexRow padding={1}>
      <Link component={RouterLink} to="/account" variant="body2" color="inherit">
        My Account
      </Link>
    </FlexRow>
  )
}

const GetStarted: React.FC<BoxProps> = (props) => {
  const authenticated = isAuthenticated()
  return (
    <FlexCol {...props}>
      <Typography variant="subtitle1">Get Started</Typography>
      {authenticated ? <MyAccountRow /> : <CreateAccountRow />}
      <FlexRow padding={1}>
        <Link target="_blank" href="https://coinapp.co/support/" variant="body2" color="inherit">
          Support
        </Link>
      </FlexRow>
    </FlexCol>
  )
}

export default GetStarted

import { useMediaQuery, useTheme } from '@material-ui/core'
import { useUserEvents } from '@xyo-network/sdk-coin-react-js'
import { isAuthenticated, useAuthUser } from '@xyo-network/sdk-coin-react-js/auth'
import { FlexGrowCol, FlexGrowRow, InvertableThemeProvider } from '@xyo-network/sdk-xyo-react-js'
import React, { useEffect } from 'react'

import { AccountHero } from '../../../Components'
import BasePage from '../../BasePage'
import coinScene from '../img/coin-scene.svg'
import { SigninAccountInteractionArea, SigninAccountSuccessInteractionArea } from '../InteractionArea'

interface Props {
  funnelName: string
}

const Page: React.FC<Props> = (props) => {
  const { funnelName } = props
  const userEvents = useUserEvents()
  const theme = useTheme()
  const desktop = useMediaQuery(theme.breakpoints.up('md'))
  const authenticated = isAuthenticated()
  const user = useAuthUser()

  useEffect(() => {
    userEvents.viewContent({ name: funnelName, path: document.location.href })
  }, [userEvents, funnelName])

  if (authenticated === undefined) {
    return <BasePage container="xl" disableGutters hideAppBar />
  }

  return (
    <BasePage hideAppBar>
      {desktop ? (
        <FlexGrowRow alignItems="stretch" minHeight="90vh">
          <InvertableThemeProvider dark={true}>
            <AccountHero flexGrow={1} flexBasis={50} padding={2} image={coinScene} titleAlign="center" textZoom={2} />
          </InvertableThemeProvider>
          {authenticated ? (
            <SigninAccountSuccessInteractionArea email={user?.email ?? ''} />
          ) : (
            <SigninAccountInteractionArea margin={4} />
          )}
        </FlexGrowRow>
      ) : (
        <FlexGrowCol alignItems="stretch" minHeight="90vh">
          <InvertableThemeProvider dark={true}>
            <AccountHero image={coinScene} paddingLeft={0.5} minHeight={260} />
          </InvertableThemeProvider>
          {authenticated ? (
            <SigninAccountSuccessInteractionArea email={user?.email ?? ''} />
          ) : (
            <SigninAccountInteractionArea margin={2} />
          )}
        </FlexGrowCol>
      )}
    </BasePage>
  )
}

export default Page

import offerImage from './img/sweepstakes/ipad/320x180.jpg'
import SweepstakesBasePage from './SweepstakesBasePage'

const Page: React.FC = () => {
  return (
    <SweepstakesBasePage
      optionsId={''}
      funnelName="ipad"
      offerImage={offerImage}
      offerTitle="iPad (10.2-inch, Wi-Fi, 32GB)"
    />
  )
}

export default Page

import { MenuItem, Select, Toolbar } from '@material-ui/core'
import { CoinFeedbackSubmissionStatus } from '@xylabs/sdk-coin-js'
import { useHistory } from 'react-router-dom'

interface Props {
  filter?: CoinFeedbackSubmissionStatus
}

const ReviewToolbar: React.FC<Props> = (props) => {
  const { filter } = props
  const history = useHistory()

  const onFilterChange = (
    event: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>
  ) => {
    history.push(`/feedback/review/${event.target.value}`)
  }

  return (
    <Toolbar>
      <Select
        onChange={onFilterChange}
        value={filter ?? CoinFeedbackSubmissionStatus.All}
        variant="outlined"
        margin="dense"
      >
        <MenuItem value={CoinFeedbackSubmissionStatus.All}>All</MenuItem>
        <MenuItem value={CoinFeedbackSubmissionStatus.Approved}>Approved</MenuItem>
        <MenuItem value={CoinFeedbackSubmissionStatus.Disapproved}>Disapproved</MenuItem>
        <MenuItem value={CoinFeedbackSubmissionStatus.Incomplete}>Incomplete</MenuItem>
        <MenuItem value={CoinFeedbackSubmissionStatus.Submitted}>Submitted</MenuItem>
        <MenuItem value={CoinFeedbackSubmissionStatus.Deleted}>Deleted</MenuItem>
        <MenuItem value={CoinFeedbackSubmissionStatus.Flagged}>Flagged</MenuItem>
      </Select>
    </Toolbar>
  )
}

export default ReviewToolbar

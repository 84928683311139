import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import HomePage from './Home'

const Pages: React.FC = () => {
  return (
    <Switch>
      <Route component={HomePage} exact path="/" />
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  )
}

export default Pages

import { CoinRedeemSku } from '@xylabs/sdk-coin-js'

import ImageSet from './ImageSet'
import fireCubeSquareThumb from './img/sweepstakes/fire-cube/128x128.jpg'
import fireCubeLandscapeThumb from './img/sweepstakes/fire-cube/160x90.jpg'
import fireCubeLandscape from './img/sweepstakes/fire-cube/320x180.jpg'
import fireCubeSquare from './img/sweepstakes/fire-cube/320x320.jpg'
import fryerSquareThumb from './img/sweepstakes/fryer/128x128.jpg'
import fryerLandscapeThumb from './img/sweepstakes/fryer/160x90.jpg'
import fryerLandscape from './img/sweepstakes/fryer/320x180.jpg'
import fryerSquare from './img/sweepstakes/fryer/320x320.jpg'
import goproSquareThumb from './img/sweepstakes/gopro/128x128.jpg'
import goproLandscapeThumb from './img/sweepstakes/gopro/160x90.jpg'
import goproLandscape from './img/sweepstakes/gopro/320x180.jpg'
import goproSquare from './img/sweepstakes/gopro/320x320.jpg'
import ipadSquareThumb from './img/sweepstakes/ipad/128x128.jpg'
import ipadLandscapeThumb from './img/sweepstakes/ipad/160x90.jpg'
import ipadLandscape from './img/sweepstakes/ipad/320x180.jpg'
import ipadSquare from './img/sweepstakes/ipad/320x320.jpg'
import oculusSquareThumb from './img/sweepstakes/oculus/128x128.jpg'
import oculusLandscapeThumb from './img/sweepstakes/oculus/160x90.jpg'
import oculusLandscape from './img/sweepstakes/oculus/320x180.jpg'
import oculusSquare from './img/sweepstakes/oculus/320x320.jpg'
import ps5SquareThumb from './img/sweepstakes/ps5/128x128.jpg'
import ps5LandscapeThumb from './img/sweepstakes/ps5/160x90.jpg'
import ps5Landscape from './img/sweepstakes/ps5/320x180.jpg'
import ps5Square from './img/sweepstakes/ps5/320x320.jpg'
import samsung65InchSquareThumb from './img/sweepstakes/samsung-65-inch/128x128.jpg'
import samsung65InchLandscapeThumb from './img/sweepstakes/samsung-65-inch/160x90.jpg'
import samsung65InchLandscape from './img/sweepstakes/samsung-65-inch/320x180.jpg'
import samsung65InchSquare from './img/sweepstakes/samsung-65-inch/320x320.jpg'
import switchSquareThumb from './img/sweepstakes/switch/128x128.jpg'
import switchLandscapeThumb from './img/sweepstakes/switch/160x90.jpg'
import switchLandscape from './img/sweepstakes/switch/320x180.jpg'
import switchSquare from './img/sweepstakes/switch/320x320.jpg'
import switchBundleSquareThumb from './img/sweepstakes/switch-bundle/128x128.jpg'
import switchBundleLandscapeThumb from './img/sweepstakes/switch-bundle/160x90.jpg'
import switchBundleLandscape from './img/sweepstakes/switch-bundle/320x180.jpg'
import switchBundleSquare from './img/sweepstakes/switch-bundle/320x320.jpg'
import xboxSquareThumb from './img/sweepstakes/xbox/128x128.jpg'
import xboxLandscapeThumb from './img/sweepstakes/xbox/160x90.jpg'
import xboxLandscape from './img/sweepstakes/xbox/320x180.jpg'
import xboxSquare from './img/sweepstakes/xbox/320x320.jpg'
import xyoTokensSquareThumb from './img/sweepstakes/xyo-tokens/128x128.jpg'
import xyoTokensLandscapeThumb from './img/sweepstakes/xyo-tokens/160x90.jpg'
import xyoTokensLandscape from './img/sweepstakes/xyo-tokens/320x180.jpg'
import xyoTokensSquare from './img/sweepstakes/xyo-tokens/320x320.jpg'

const SkuToImageSet: Record<string, ImageSet> = {
  [CoinRedeemSku.AmazonFireCube]: {
    landscape: {
      image: fireCubeLandscape,
      thumb: fireCubeLandscapeThumb,
    },
    square: {
      image: fireCubeSquare,
      thumb: fireCubeSquareThumb,
    },
  },
  [CoinRedeemSku.CosoriSmartAirFryer]: {
    landscape: {
      image: fryerLandscape,
      thumb: fryerLandscapeThumb,
    },
    square: {
      image: fryerSquare,
      thumb: fryerSquareThumb,
    },
  },
  [CoinRedeemSku.GoPro]: {
    landscape: {
      image: goproLandscape,
      thumb: goproLandscapeThumb,
    },
    square: {
      image: goproSquare,
      thumb: goproSquareThumb,
    },
  },
  [CoinRedeemSku.IPad]: {
    landscape: {
      image: ipadLandscape,
      thumb: ipadLandscapeThumb,
    },
    square: {
      image: ipadSquare,
      thumb: ipadSquareThumb,
    },
  },
  [CoinRedeemSku.Oculus]: {
    landscape: {
      image: oculusLandscape,
      thumb: oculusLandscapeThumb,
    },
    square: {
      image: oculusSquare,
      thumb: oculusSquareThumb,
    },
  },
  [CoinRedeemSku.OneMillionXyoTokens]: {
    landscape: {
      image: xyoTokensLandscape,
      thumb: xyoTokensLandscapeThumb,
    },
    square: {
      image: xyoTokensSquare,
      thumb: xyoTokensSquareThumb,
    },
  },
  [CoinRedeemSku.NintendoSwitch]: {
    landscape: {
      image: switchLandscape,
      thumb: switchLandscapeThumb,
    },
    square: {
      image: switchSquare,
      thumb: switchSquareThumb,
    },
  },
  [CoinRedeemSku.NintendoSwitchBundle]: {
    landscape: {
      image: switchBundleLandscape,
      thumb: switchBundleLandscapeThumb,
    },
    square: {
      image: switchBundleSquare,
      thumb: switchBundleSquareThumb,
    },
  },
  [CoinRedeemSku.XboxSeriesX]: {
    landscape: {
      image: xboxLandscape,
      thumb: xboxLandscapeThumb,
    },
    square: {
      image: xboxSquare,
      thumb: xboxSquareThumb,
    },
  },
  [CoinRedeemSku.PlayStation5]: {
    landscape: {
      image: ps5Landscape,
      thumb: ps5LandscapeThumb,
    },
    square: {
      image: ps5Square,
      thumb: ps5SquareThumb,
    },
  },
  [CoinRedeemSku.Samsung65Inch]: {
    landscape: {
      image: samsung65InchLandscape,
      thumb: samsung65InchLandscapeThumb,
    },
    square: {
      image: samsung65InchSquare,
      thumb: samsung65InchSquareThumb,
    },
  },
}

export default SkuToImageSet

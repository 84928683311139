import { Typography } from '@material-ui/core'
import { useUserEvents } from '@xyo-network/sdk-coin-react-js'
import { FlexCol, FlexGrowCol, FlexGrowRow, FlexPaper, InvertableThemeProvider } from '@xyo-network/sdk-xyo-react-js'
import React, { useEffect, useState } from 'react'

import BasePage from '../BasePage'
import { FunnelStepper, InstallUpsell, SentinelXUpsell } from './Components'

interface Props {
  funnelName: string
  offerImage: string
  offerTitle: string
  optionsId: string
}

const Page: React.FC<Props> = (props) => {
  const { optionsId, funnelName, offerImage, offerTitle } = props
  const userEvents = useUserEvents()
  const [done, setDone] = useState(false)

  useEffect(() => {
    userEvents.viewContent({ name: funnelName, path: document.location.href })
  }, [userEvents, funnelName])

  const onDone = () => {
    setDone(true)
  }

  return (
    <BasePage container="sm">
      <FlexPaper marginY={2}>
        <FlexGrowCol alignItems="stretch" marginY={2} marginX={1}>
          <FlexCol alignItems="stretch" marginY={1}>
            <FlexGrowCol alignItems="stretch" marginY={2}>
              <FlexGrowRow>
                <img src={offerImage} width="70%" height="auto" />
              </FlexGrowRow>
              <FlexCol paddingY={1}>
                <Typography variant="h2" align="center">
                  Get FREE Entries to Win
                </Typography>
                <Typography variant="h3" align="center">
                  a {offerTitle}
                </Typography>
              </FlexCol>
              <Typography variant="caption" align="center">
                *No Purchase Required
              </Typography>
            </FlexGrowCol>
            <FlexGrowRow marginX={1} justifyContent="stretch">
              <FunnelStepper optionsId={optionsId} share onDone={onDone} />
            </FlexGrowRow>
          </FlexCol>
          <FlexCol display={done ? 'flex' : 'none'} marginBottom={2} alignItems="stretch">
            <InvertableThemeProvider dark={true}>
              <FlexPaper margin={1} padding={2} elevation={5}>
                <SentinelXUpsell flexGrow={1} />
              </FlexPaper>
            </InvertableThemeProvider>
            <InstallUpsell margin={1} />
          </FlexCol>
        </FlexGrowCol>
      </FlexPaper>
    </BasePage>
  )
}

export default Page

import { BoxProps, Link, Typography } from '@material-ui/core'
import { useAuthUser } from '@xyo-network/sdk-coin-react-js/auth'
import { FlexCol, FlexRow } from '@xyo-network/sdk-xyo-react-js'
import { Link as RouterLink } from 'react-router-dom'

const About: React.FC<BoxProps> = (props) => {
  const currentUser = useAuthUser()
  return (
    <FlexCol {...props}>
      <Typography variant="subtitle1">Coinapp.co</Typography>
      <FlexRow padding={1}>
        <Link target="_blank" href="https://coinapp.co/about" variant="body2" color="inherit">
          About
        </Link>
      </FlexRow>
      <FlexRow padding={1} display={currentUser ? 'none' : 'block'}>
        <Link component={RouterLink} to="/account/signin" variant="body2" color="inherit">
          Sign In
        </Link>
      </FlexRow>
      <FlexRow padding={1} display={currentUser ? 'block' : 'none'}>
        <Link component={RouterLink} to="/account/signout" variant="body2" color="inherit">
          Sign Out
        </Link>
      </FlexRow>
    </FlexCol>
  )
}

export default About

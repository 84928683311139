/* eslint @typescript-eslint/ban-types:0 */
import * as styledComponents from 'styled-components'

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<any>

function withProps<U>() {
  return <P extends keyof JSX.IntrinsicElements | React.ComponentType<any>, T extends object, O extends object = {}>(
    fn: styledComponents.ThemedStyledFunction<P, T, O>
  ): styledComponents.ThemedStyledFunction<P & U, T, O & U> =>
    fn as unknown as styledComponents.ThemedStyledFunction<P & U, T, O & U>
}

export { createGlobalStyle, css, keyframes, ThemeProvider, withProps }

export default styled

import { Paper, Theme, useTheme } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import React from 'react'

import amex from './img/amex.svg'
import discover from './img/discover.svg'
import mastercard from './img/mastercard.svg'
import visa from './img/visa.svg'

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    image: {
      width: 32,
    },
    paper: {
      border: '1px solid gray',
      lineHeight: 0,
      marginRight: 2,
    },
    root: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      height: 24,
    },
  })
)

const CreditCardLogos: React.FC = () => {
  const classes = useStyles(useTheme())
  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <img alt="Visa" className={classes.image} src={visa}></img>
      </Paper>
      <Paper className={classes.paper} elevation={0}>
        <img alt="Mastercard" className={classes.image} src={mastercard} />
      </Paper>
      <Paper className={classes.paper} elevation={0}>
        <img alt="American Express" className={classes.image} src={amex} />
      </Paper>
      <Paper className={classes.paper} elevation={0}>
        <img alt="Discover" className={classes.image} src={discover} />
      </Paper>
    </div>
  )
}

export default CreditCardLogos

import { BoxProps, Typography } from '@material-ui/core'
import { ButtonEx, FlexCol, FlexRow } from '@xyo-network/sdk-xyo-react-js'
import React from 'react'

interface SuccessBoxProps extends BoxProps {
  onDownloadClick: () => boolean
}

const SuccessBox: React.FC<SuccessBoxProps> = (props) => {
  const { onDownloadClick, title = 'Success!', ...rootProps } = props

  const onLocalDownloadClick = () => {
    const cancel = onDownloadClick?.() ?? false
    if (!cancel) {
      window.open('https://coin.onelink.me/ePJg/b8149573')
    }
  }

  return (
    <FlexCol alignItems="stretch" justifyContent="flex-start" {...rootProps}>
      <FlexRow margin={1} justifyContent="flex-start">
        <Typography variant="h3">{title}</Typography>
      </FlexRow>
      <FlexRow margin={1} justifyContent="flex-start">
        <Typography variant="h5">What do I do next?</Typography>
      </FlexRow>
      <FlexRow margin={1} justifyContent="flex-start">
        <Typography variant="body1">
          Now you need to download COIN! Inside COIN, you can earn rewards for validating location data, playing
          minigames, doing fun tasks, and more!
        </Typography>
      </FlexRow>
      <FlexRow margin={1} justifyContent="flex-start">
        <ButtonEx fullWidth variant="contained" color="secondary" onClick={onLocalDownloadClick}>
          Download COIN
        </ButtonEx>
      </FlexRow>
      <FlexRow margin={1} justifyContent="flex-start">
        <Typography variant="body1">
          The button will take you to whatever app store is on your device. COIN is available for iOS and for Android!
        </Typography>
      </FlexRow>
      <FlexRow margin={1} justifyContent="flex-start">
        <Typography variant="body1">If you are on desktop, we'll take you to the COIN Website.</Typography>
      </FlexRow>
    </FlexCol>
  )
}

export default SuccessBox

import { CardActions, Typography, useTheme } from '@material-ui/core'
import { FlexGrowRow, FlexRow, useAsyncEffect } from '@xyo-network/sdk-xyo-react-js'
import { useState } from 'react'

import { useApi } from '../../../../Contexts'
import OfferProps from '../../../OfferProps'
import offersFromSweepstakes from '../../../offersFromSweepstakes'
import SweepstakeProps from './SweepstakeProps'

const InactiveCardActions: React.FC<SweepstakeProps> = (props) => {
  const { sweepstake } = props
  const [offers, setOffers] = useState<(OfferProps | null)[]>()
  const api = useApi()
  const [apiError, setApiError] = useState<Error>()

  const theme = useTheme()

  useAsyncEffect(
    async (mounted) => {
      if (api && !apiError) {
        try {
          const sweepstakes = await api.getSweepstakes()
          if (mounted()) {
            const offers = sweepstakes ? offersFromSweepstakes(sweepstakes, true) : undefined
            setOffers(offers)
          }
        } catch (ex) {
          setApiError(ex)
        }
      }
    },
    [api, apiError]
  )

  let offerToUse: OfferProps | undefined

  offers?.forEach((offer) => {
    if (sweepstake.sku === offer?.sku) {
      offerToUse = offer ?? undefined
    }
  })

  const { winnerImage, winner = 'Pending' } = offerToUse ?? ({} as OfferProps)

  return (
    <CardActions
      disableSpacing={true}
      style={{
        display: 'flex',
        justifyContent: 'stretch',
      }}
    >
      <FlexGrowRow justifyContent="space-between">
        <FlexRow>
          <Typography variant="caption">{`Winner: ${winner}`}</Typography>
          {winnerImage ? (
            <FlexRow margin={1} border={1} borderRadius={theme.shape.borderRadius} overflow="hidden">
              <img height={32} width={32} src={winnerImage} />
            </FlexRow>
          ) : null}
        </FlexRow>
      </FlexGrowRow>
    </CardActions>
  )
}

export default InactiveCardActions

import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { CoinDashboardJsonPage, CoinDashboardPage } from './CoinDasboard'
import { CoinStatsJsonPage, CoinStatsPage } from './CoinStats'
import { SweepstakesDashboardJsonPage, SweepstakesDashboardPage } from './SweepstakesDashboard'
import { UserPage } from './User'

const Pages: React.FC = () => {
  return (
    <Switch>
      <Route component={UserPage} exact path="/u" />
      <Route component={CoinDashboardPage} exact path="/u/coin" />
      <Route component={CoinDashboardJsonPage} exact path="/u/coin/json" />
      <Route component={CoinStatsPage} exact path="/u/coin/stats" />
      <Route component={CoinStatsJsonPage} exact path="/u/coin/stats/json" />
      <Route component={SweepstakesDashboardPage} exact path="/u/sweepstakes" />
      <Route component={SweepstakesDashboardJsonPage} exact path="/u/sweepstakes/json" />
      <Route>
        <Redirect to="/u" />
      </Route>
    </Switch>
  )
}

export default Pages

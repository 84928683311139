import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import FeedbackFunnelPage from './FeedbackFunnelPage'
import FeedbackOfferPage from './FeedbackOfferPage'
import { FeedbackReviewPage } from './ReviewPage'

const Pages: React.FC = () => {
  return (
    <Switch>
      <Route component={FeedbackFunnelPage} exact path={'/feedback'} />
      <Route component={FeedbackReviewPage} exact path={'/feedback/review'} />
      <Route component={FeedbackReviewPage} exact path={'/feedback/review/:filter'} />
      <Route component={FeedbackOfferPage} exact path="/feedback/:id" />
      <Route>
        <Redirect to="/feedback" />
      </Route>
    </Switch>
  )
}

export default Pages
